import { React, useEffect } from 'react';
import { Translate, translate, useLanguage, useLocale } from '../Locale'

export default function SupportUs(props) {
    return (
        <div id="support-us">
            <div className="row mt-3">
                <div className="col">
                    <h2>
                        <Translate string="support-us-title" />
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="border-top" />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p>
                        <Translate string="support-us-body-1" />
                    </p>
                    {/*
                    <p>
                        <Translate string="support-us-body-2" />
                    </p>
                    */}
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="card text-center mb-3">
                        <div className="card-body">
                            <h5 className="card-title">
                                <Translate string="support-us-body-3" />
                            </h5>
                            <p className="card-text">
                                <Translate string="support-us-body-4" />
                            </p>
                            <p className="card-text">
                                <Translate string="support-us-body-5" />
                            </p>
                            <div className="card-text">
                                <PayPalPopUp />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function usePayPalResources() {
    const lang = useLanguage();
    const locale = useLocale();

    var imgSrc;
    if (lang === "en") {
        imgSrc = "https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif";
    } else {
        imgSrc = "https://www.paypalobjects.com/fr_CA/i/btn/btn_donateCC_LG.gif";
    }

    return {
        id: "5YAW9QXF4LD9U",
        slogan: "PayPal - The safer, easier way to pay online!",
        imgSrc,
        imgAlt: translate("support-us-paypal-button", locale)
    }
}

function PayPalForm(props) {
    const resources = usePayPalResources();

    return (
        <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="hosted_button_id" value={resources.id} />
            <input type="image" src={resources.imgSrc} border="0" name="submit" title={resources.slogan} alt={resources.imgAlt} />
            <img alt="" border="0" src="https://www.paypal.com/en_CA/i/scr/pixel.gif" width="1" height="1" />
        </form>
    );
}

// Uncomment the paypal script on index.html to use this function
// and comment it out again if not using it.
function PayPalPopUp(props) {
    const resources = usePayPalResources();

    useEffect(() => {
        const element = document.getElementById("donate-button");
        if (element.hasChildNodes()) {
            return;
        }

        PayPal.Donation.Button({
            env: 'production',
            hosted_button_id: resources.id,
            image: {
                src: resources.imgSrc,
                alt: resources.imgAlt,
                title: resources.slogan,
            }
        }).render('#donate-button');
    }, []);

    return (
        <div id="donate-button-container">
            <div id="donate-button"></div>
        </div>
    );
}
