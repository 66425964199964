import { React } from 'react';
import { Link } from "react-router-dom";
import { SearchBreadcrumbs } from '../components/Breadcrumbs';
import { ReferralButton } from '../components/ReferralButton';
import { ProductImage } from '../components/ProductImage';
import { PriceBadges, IsDiscounted } from './PriceBadges';

import { Translate, useLocale, useCurrency, formatAsCurrency } from '../Locale'

export function ProductListImpl(props) {
    const products = props.products;
    const categories = props.categories;

    var productList;
    if (products.length === 0) {
        productList = <ZeroResults/>
    } else {
        productList = products.map((product, i) =>
            <Product key={product.code} first={i === 0} product={product} categories={categories}/>
        );
    }

    return (
        <div id="productList">
            {productList}
        </div>
    );
}

function ZeroResults(props) {
    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="border-top"/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p><Translate string="product-list-no-results"/></p>
                </div>
            </div>
        </>
    );
}

function Product(props) {
    const first = props.first;
    const product = props.product;
    const categories = props.categories;

    return (
        <>
            <div className={first ? "row" : "row mt-3"}>
                <div className="col">
                    <div className="border-top"/>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-3 text-center" style={{height: '150px'}}>
                    <ProductImage product={product} thumbnail={true} />
                </div>
                <div className="col-9">
                    <div className="row">
                        <div className="col">
                            <Title product={product} />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            <SearchBreadcrumbs categories={categories} product={product} showLink={true}/>
                        </div>
                    </div>
                    <div className={`row mt-1${product.model ? '' : ' d-none'}`}>
                        <div className="col">
                            <Model product={product} />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            <Price product={product} />
                        </div>
                    </div>
                    <div className={`row mt-1${IsDiscounted(product) ? '' : ' d-none'}`}>
                        <div className="col">
                            <PriceBadges product={product} />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            <ReferralButton product={product} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function Title(props) {
    const product = props.product;
    return <Link to={`/product/${product.code}`} title={product.title}>{product.title}</Link>
}

function Model(props) {
    const product = props.product;
    if (product.model)
        return <><Translate string="model"/>: {product.model}</>
    else
        return <><Translate string="model"/>: <Translate string="na"/></>
}

function Price(props) {
    const product = props.product;
    const locale = useLocale();
    const currency = useCurrency();
    
    if (product.priceRange && product.priceRange.length) {
        if (product.priceRange.length === 2) {
            const price1 = formatAsCurrency(product.priceRange[0], locale, currency);
            const price2 = formatAsCurrency(product.priceRange[1], locale, currency);
            return <><Translate string="price"/>: {`${price1} — ${price2}`}</>
        }
        else {
            const price = formatAsCurrency(product.priceRange[0], locale, currency);
            return <><Translate string="price"/>: {price}</>;
        }   
    } else {
        return <Translate string="in-store-only"/>
    }
}
