import { React } from 'react';
import { useIndexAndCategoriesData } from '../DataFetcher';
import { ProductGrid } from '../components/ProductGrid'
import { useLocale, translate } from '../Locale'

export default function TopPriceDrops(props) {
  const locale = useLocale();
  const [isLoading, isError, error, index, categories] = useIndexAndCategoriesData();
  
  const title = translate("top-price-drops-title", locale);
  const subtitle = null; //translate("top-price-drops-title", locale);
  
  if (isError) {
    throw error;
  }

  if (isLoading) {
    return <ProductGrid title={title} subtitle={subtitle} loading={true} products={null} categories={null} />;
  }

  const products = getDiscountedProducts(index);

  return <ProductGrid title={title} subtitle={subtitle} products={products} categories={categories} />;
}

function getDiscountedProducts(index) {
  const products = [];

  for (const [code, value] of Object.entries(index)) {
      if (value.disc && value.disc.length !== 0) {
          products.splice(0, 0, { code, ...value });
      }
  }

  return products;
}
