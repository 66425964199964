import { React } from 'react';
import { translate, useLocale, useLanguage } from '../Locale'
import { useSearchParams } from "react-router-dom";
import { logOpenReferral } from '../FirebaseFunctions'
import { getReferralUrl } from '../ReferralUtils'

export function ReferralLink(props) {
    const [searchParams] = useSearchParams();
    const variantCode = searchParams.get("variant");

    const language = useLanguage();
    const product = props.product;
    const className = props.className;
    const title = props.title;
    const text = props.text;

    function click() {
        const price = getPrice(product, variantCode);
        logOpenReferral(product.code, variantCode, price, "website");
    }

    const url = getReferralUrl(product.code, variantCode, language);

    return (
        <a href={url} title={title} className={className} onClick={click} target="_blank" rel="noreferrer">
            {text}
        </a>
    );
}

export function ReferralButton(props) {
    const locale = useLocale();
    const product = props.product;
    const fullWidth = props.fullWidth;
    var text = props.text;

    var className;
    if (fullWidth) {
        className="btn btn-light btn-outline-primary w-100";
    } else {
        className="btn btn-light btn-outline-primary";
    }

    if (text) {
        text = translate(text, locale);
    } else {
        text = translate("buy-button-default-text", locale);
    }

    return <ReferralLink product={product} className={className} title={null} text={text} />
}

function getPrice(product, variantCode) {
    // We have two different product prototypes, "index" and "details".

    // 1) Index
    if (product.priceRange) {
        return getFirstOrDefaultIndexPrice(product);
    }

    // 2) Details
    if (product.variants) {
        return getFirstOrDefaultDetailsPrice(product, variantCode);
    }

    return 0;
}

function getFirstOrDefaultIndexPrice(product) {
    if (product.priceRange.length > 0)
        return product.priceRange[0];
        
    return 0;
}

function getFirstOrDefaultDetailsPrice(product, variantCode) {
    const variants = product.variants;
    if (!variants || variants.length === 0)
        return 0;

    var variant = variants.find(v => v.code === variantCode);
    if (!variant) {
        variant = variants[0];
    }

    if (!variant.priceHistory || variant.priceHistory.length === 0)
        return 0;

    return variant.priceHistory[variant.priceHistory.length - 1].price;    
}
