import { React, useRouteError } from "react-router-dom";
import { Translate } from './Locale';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  
  return (
    <div id="error-page">
      <h1><Translate string="error-page-header" /></h1>
      <p><Translate string="error-page-description" /></p>
      {getErrorMessageJsx(error)}
    </div>
  );
}

function getErrorMessageJsx(error) {
  if ((error instanceof AggregateError) === false) {
    return <i>{getErrorMessage(error)}</i>;
  }

  if (error.errors.length === 1) {
    return <i>{getErrorMessage(error.errors[0])}</i>;
  }

  const message = error.errors.map((error, i) =>
    <li key={i}>
      <i>{getErrorMessage(error)}</i>
    </li>
  );
  
  return <ul>{message}</ul>;
}

function getErrorMessage(error) {
  return error.statusText || error.message;
}