import { React } from 'react';
import { useSearchParams } from "react-router-dom";
import { usePageItems, ItemPagination } from './Pagination'

import { ProductListImpl } from './ProductListImpl';
import { ProductListToolbarSelect, ProductListToolbarDropdown } from './Toolbars';
import { Spinner } from './Spinner';

export function ProductList(props) {
    const title = props.title;
    const subtitle = props.subtitle;
    const loading = props.loading;
    const products = props.products;
    const categories = props.categories;
    
    // Search parameters
    const [orderBy, setOrderBy] = useOrderBy();
    const [filterByCategory, setFilterByCategory] = useFilterBy(categories, "c");

    var toolbar;
    var results;
    
    if (loading) {
        toolbar = null;
        results = <Spinner />;
    } else if (products === null || categories === null) {
        toolbar = null;
        results = null;
    } else {
        const displayCategories = getCategories(products, categories);
        const filteredProducts = filterProducts(products, filterByCategory);
        const orderedProducts = orderProducts(filteredProducts, orderBy);

        toolbar = <ProductListToolbarDropdown
                        categories={displayCategories}
                        orderBy={orderBy} setOrderBy={setOrderBy}
                        filterBy={filterByCategory} setFilterBy={setFilterByCategory}/>;

        results = <Results products={orderedProducts} categories={categories} />;
    }

    return (
        <div id="productList">
            <div className="row mt-3">
                <div className="col-9">
                    <h2>
                        { title }
                    </h2>
                </div>
                <div className="col-3">
                    { toolbar }
                </div>
            </div>

            <div className={subtitle ? "row mt-3" : "d-none"}>
                <div className="col">
                    <p>
                        { subtitle }
                    </p>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col">
                    { results }
                </div>
            </div>
        </div>
    );
}

export function Results(props) {
    const products = props.products;
    const categories = props.categories;

    const maxItemsPerPage = 10;
    const pageProducts = usePageItems(products, maxItemsPerPage);

    return (
        <>
            <ProductListImpl products={pageProducts} categories={categories} />
            <ItemPagination items={products} maxItemsPerPage={maxItemsPerPage}/>
        </>
    );
}

function filterProducts(items, category) {
    if (!category || category === "none") {
        return items;
    }

    const ret = [];
    items.forEach(item => {
        if (item.category === category) {
            ret.push(item);
        }
    });

    return ret;
}

function orderProducts(products, orderBy) {
    function orderByTitle(products) {
        return products.sort((a, b) => {
            if (a.title > b.title) return 1;
            if (a.title < b.title) return -1;
            return 0;
        });
    }
    
    function orderByPriceLowToHigh(products) {
        return products.sort((a, b) => {
            if (a.priceRange.length === 0 && b.priceRange.length === 0) return 0;
            if (a.priceRange.length === 0) return 1;
            if (b.priceRange.length === 0) return -1;
            return a.priceRange[0] - b.priceRange[0];
        });
    }
    
    function orderByPriceHighToLow(products) {
        return products.sort((a, b) => {
            if (a.priceRange.length === 0 && b.priceRange.length === 0) return 0;
            if (a.priceRange.length === 0) return 1;
            if (b.priceRange.length === 0) return -1;
            return b.priceRange[0] - a.priceRange[0];
        });
    }

    if (orderBy === 1) {
        // Already ordered by relevance....
        return products;
    } else if (orderBy === 2) {
        return [...orderByPriceLowToHigh(products)];
    } else if (orderBy === 3) {
        return [...orderByPriceHighToLow(products)];
    } else if (orderBy === 4) {
        return [...orderByTitle(products)];
    } else {
        return products;
    }
}

function getCategories(products, categories) {
    const map = {};
    
    products.forEach(product => {
        const key = product.category;
        if (!key) {
            return;
        }
        const value = categories[key];
        if (!value || !value.name) {
            return;
        }
        map[key] = value;
    });

    var arr = [];
    Object.entries(map).map(([code, value]) => {
        arr.push({
            code, ...value
        });
    });

    arr.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    });

    return arr;
}

function useOrderBy() {
    const [searchParams, setSearchParams] = useSearchParams();

    var orderBy = parseInt(searchParams.get("o"));
    if (!orderBy || orderBy < 1 || orderBy > 4) {
        orderBy = 1;
    }

    function setOrderBy(orderBy) {
        searchParams.set("o", orderBy);
        searchParams.set("p", 1);
        setSearchParams(searchParams);
    }

    function deleteOrderBy() {
        searchParams.delete("o");
    }

    return [orderBy, setOrderBy, deleteOrderBy];
}

function useFilterBy(categories, paramName) {
    const [searchParams, setSearchParams] = useSearchParams();

    var filterBy = searchParams.get(paramName);
    if (!filterBy || !categories || !categories[filterBy]) {
        filterBy = "none";
    }

    function setFilterBy(filterBy) {
        searchParams.set(paramName, filterBy);
        searchParams.set("p", 1);
        setSearchParams(searchParams);
    }

    function deleteFilterBy() {
        searchParams.delete(paramName);
    }

    return [filterBy, setFilterBy, deleteFilterBy];
}
