import { useIndex } from './DataFetcher';
import { useEffect, useState } from 'react';
import { logException } from './FirebaseFunctions';

//import Fuse from 'fuse.js'
const { Document } = require("flexsearch");

const profile = process.env.NODE_ENV !== "production";

// FlexSearchEngine
// https://github.com/nextapps-de/flexsearch
class FlexSearchEngine {
    constructor() {
        this.onEngineReady = null;
        this.initialized = false;
        this.initializing = false;
        this.index = null;
    }

    setEngineReadyHandler = (handler) => {
        this.onEngineReady = handler;
    }

    initAsync = async (data) => {
        if (this.initialized) {
            this.onEngineReady();
            return 1;
        }

        if (this.initializing) {
            return 2;
        }
        
        this.initializing = true;
        this.index = new Document({
            document: {
                id: 0,

                // https://github.com/nextapps-de/flexsearch#index-options
                // https://github.com/nextapps-de/flexsearch#presets
                // https://github.com/nextapps-de/flexsearch#tokenizer
                index: [{
                    field: "code",
                    preset: "default",
                    tokenize: "strict"
                },{
                    field: "title",
                    preset: "default",
                    tokenize: "forward"
                }]
            },

            options: {
                // https://github.com/nextapps-de/flexsearch#worker-parallelism-browser--nodejs
                worker: true
            }
        });
        
        const promises = [];

        for (const key in data) {
            promises.push(this.index.addAsync({ 
                id: key,
                code: key,
                title: data[key].title
            }));
        }

        await Promise.all(promises);

        this.initialized = true;
        this.initializing = false;
        this.onEngineReady();
        return 0;
    }

    search = (query) => {
        if (!this.initialized)
            throw new Error("Search engine not initialized!");

        const results = this.index.search(query);
        if (results.length === 0)
            return [];

        return this.mergeResults(results);
    }

    searchAsync = async (query) => {
        if (!this.initialized)
            throw new Error("Search engine not initialized!");
        
        const results = await this.index.searchAsync(query);
        if (results.length === 0)
            return [];
        
        return this.mergeResults(results);
    }

    mergeResults = (results) => {
        // When the index has more than one field, which is the
        // case here, search will return an array with more than
        // one element when the criteria is matched in more than
        // one field, thus we should merge the results.
        // TODO: merge results
        return results[0].result;
    }
}

// FlexSearch, Fuse, Lunr.
// https://npmtrends.com/flexsearch-vs-fuse.js-vs-lunr
var engine = new FlexSearchEngine();

export function useSearchEngine() {
    const index = useIndex();
    const [engineReady, setEngineReady] = useState(false);
    const [engineError, setEngineError] = useState(null);

    useEffect(() => {
        if (index.isLoading || index.isError) {
            return;
        }

        if (engineReady || engineError !== null) {
            return;
        }

        const s = Date.now();
        engine.initAsync(index.data).then((ret) => {
            if (profile && ret === 0) {
                const f = Date.now();
                console.info(`Time elapsed loading search engine: ${f - s}ms`);
            }
        }).catch((error) => {
            logException(`Error initializing search engine: ${error.message}`, true);
            setEngineReady(false);
            setEngineError(error);
        });
    }, [index]);

    engine.setEngineReadyHandler(() => {
        setEngineReady(true);
        setEngineError(null);
    });

    var error = null;
    if (index.isError || engineError) {
        if (index.isError && engineError) {
            error = new AggregateError([index.error, engineError]);
        } else {
            error = index.isError ? index.error : engineError;
        }
    }

    return {
        isLoading : error === null && (index.isLoading || !engineReady),
        isError: error !== null,
        error: error,
        search: (q) => { return engine.search(q); },
        searchAsync: (q) => { return engine.searchAsync(q); }
    };
}

/*
class FuseSearchEngine {
    constructor(data) {
        this.data = data;
        this.fuse = null;
        this.init();
    }

    init = () => {
        const options = {
            // isCaseSensitive: false,
            includeScore: true,
            // shouldSort: true,
            // includeMatches: false,
            // findAllMatches: false,
            // minMatchCharLength: 1,
            // location: 0,
            threshold: 0.3,
            distance: 200,
            // useExtendedSearch: false,
            //ignoreLocation: true,
            // ignoreFieldNorm: false,
            // fieldNormWeight: 1,
            keys: [
                "title"
            ]
        };

        this.fuse = new Fuse(this.data, options);
    }

    // https://github.com/krisk/Fuse/issues/569
    // https://fusejs.io/api/options.html
    search = (query) => {
        return this.fuse.search(query);
    }
}
*/
