import { React } from 'react';
import { getUriPrefix } from '../DataFetcher';

function buildProductImageUrl(code, thumbnail) {
    const pathPrefix = getUriPrefix();
    return `${pathPrefix}/images/products/${code.toLowerCase()}.png`;
}

function getProductImageNotFoundUrl() {
    const pathPrefix = getUriPrefix();
    return `${pathPrefix}/images/404.jpg`;
}

export function ProductImage(props) {
    const code = props.product.code;
    const title = props.product.title;
    const thumbnail = props.thumbnail;

    // https://getbootstrap.com/docs/5.3/content/images/
    // "Images in Bootstrap are made responsive with .img-fluid.
    // This applies max-width: 100%; and height: auto; to the
    // image so that it scales with the parent width."
    const suffix = thumbnail ? "-thumbnail" : "";
    return (
        <div className={`text-center product-image-container${suffix}`}>
            <div className="row h-100 align-items-center">
                <div className="col">
                    <img
                        className={`img-fluid product-image${suffix}`}
                        alt={title}
                        src={buildProductImageUrl(code, thumbnail)}
                        onError={(e) => {
                            e.currentTarget.src = getProductImageNotFoundUrl();
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
