import { React } from 'react';
import { Translate, useLocale, useCurrency, formatAsCurrency } from '../Locale'

const styleDiscount = "border border-success text-success"; // text-bg-success
const styleBestPrice = "border border-success text-success"; // text-bg-success

export function IsDiscounted(product) {
    return product.disc && product.disc.length === 3;
}

export function PriceBadges(props) {
    const product = props.product;
    return (
        <>
            <DiscountPercentageBadge product={product}/>
            <BestPriceBadge product={product} />
        </>
    );
}

export function DiscountPercentageBadge(props) {
    const product = props.product;

    if (!IsDiscounted(product)) {
        return null;
    }

    if (product.disc[2] < 1) {
        return null;
    }

    return (
        <span className={`badge ${styleDiscount} me-1`}>
            {product.disc[2]}% <Translate string="price-badges-off"/>
        </span>
    );
}

export function DiscountValueBadge(props) {
    const locale = useLocale();
    const currency = useCurrency();
    const product = props.product;

    if (!IsDiscounted(product)) {
        return null;
    }

    return (
        <span className={`badge ${styleDiscount} me-1`}>
            {formatAsCurrency(product.disc[1], locale, currency)} off
        </span>
    );
}

export function BestPriceBadge(props) {
    const product = props.product;

    if (!IsDiscounted(product)) {
        return null;
    }

    if (product.disc[0] !== 1) {
        return null;
    }

    return (
        <span className={`badge ${styleBestPrice}`}>
            <Translate string="price-badges-best-price"/>
        </span>
    )
}

// <span className={`badge ${styleDiscount}`}>{formatAsCurrency(product.disc[1], locale, currency)} OFF</span>
