import { React } from 'react';

export default function Features(props) {

    const title = "Features";

    return (
        <div id="features">
            <div className="row mt-3">
                <div className="col-9">
                    <h2>
                        { title }
                    </h2>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <div className="border-top"/>
                </div>
            </div>
        </div>
    );

}
