
// https://stackoverflow.com/a/34015511
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat#options

export function addDay(date) {
    return addDays(date, 1);
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function date_eq(date1, date2, utc) {
    if (utc)
        return toCanonicalUTCDate(date1) === toCanonicalUTCDate(date2);
    else
        return toCanonicalLocalDate(date1) === toCanonicalLocalDate(date2);
}

export function date_le(date1, date2, utc) {
    if (utc)
        return toCanonicalUTCDate(date1) <= toCanonicalUTCDate(date2);
    else
        return toCanonicalLocalDate(date1) <= toCanonicalLocalDate(date2);
}

export function snapToThursday(date) {
    const daysLUT = new Array(7);
    daysLUT[4] = 0;  // Thu 4
    daysLUT[5] = -1; // Fri 5
    daysLUT[6] = -2; // Sat 6
    daysLUT[0] = -3; // Sun 0
    daysLUT[1] = -4; // Mon 1
    daysLUT[2] = -5; // Tue 2
    daysLUT[3] = -6; // Wed 3
    const days = daysLUT[date.getDay()];
    return addDays(date, days);
}

function toCanonicalUTCDate(dt) {
    return dt.toISOString().slice(0, -14);
}

function toCanonicalLocalDate(dt) {
    const offset = (new Date()).getTimezoneOffset() * 60000;
    return (new Date(dt - offset)).toISOString().slice(0, -14);
}
