import { React } from 'react';
import { Translate } from '../Locale'
import { Link } from "react-router-dom";

export default function Home() {

  return (
    <div id="home">
      <div className="row mt-3">
        <div className="col-12">
          <h4>
            <Translate string="home-title" />
          </h4>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col">
          {/*
          <p>
            <Translate string="home-description-1" />
          </p>
           */}
          <p>
            <Translate string="home-description-2" />
          </p>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col">
          <div className="border-top" />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-sm-12 col-md-6">
          <div className="row">
            <div className="col-3">
              <p className="home-promo-icon text-center">
                <i className="bi bi-envelope-paper" />
              </p>
            </div>
            <div className="col-9">
              <h4>
                <Translate string="home-promo-title-1" />
              </h4>
              <p>
                <Translate string="home-promo-subtitle-1" />
              </p>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-6">
          <div className="row">
            <div className="col-3">
              <p className="home-promo-icon text-center">
                <i className="bi bi-graph-down-arrow" />
              </p>
            </div>
            <div className="col-9">
              <h4>
                <Translate string="home-promo-title-2" />
              </h4>
              <p>
                <Translate string="home-promo-subtitle-2" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="border-top" />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <p>
            <Translate string="home-description-3" />
          </p>
          <ul>
            <li>
              <Link to="/search">
                <Translate string="navbar-search"/>
              </Link>
            </li>
            <li>
              <Link to="/top">
                <Translate string="navbar-top-price-drops"/>
              </Link>
            </li>
            {/*
            <li>
              <Link to="/pop">
                <Translate string="navbar-popular-products"/>
              </Link>
            </li>
            */}
          </ul>
        </div>
      </div>

    </div>
  );
}
