import { React } from 'react';
import { translate, useLocale } from '../Locale';

export function Alert(props) {
    const locale = useLocale();
    const show = props.show;
    const type = props.type;
    const body = props.body;
    const handleClose = props.onClose;

    if (!show) {
        return null;
    }

    const ariaLabel = translate("alert-close", locale);

    return (
        <div id="alert" className={`alert alert-${type} alert-dismissible show`} role="alert">
            { body }
            <button type="button" className="btn-close" aria-label={ariaLabel} onClick={handleClose}></button>
        </div>
    );
}
