import { React } from 'react';
import { Link } from "react-router-dom";

export default function PopularProducts(props) {
  // 0 - Lowest prices on record
  // 1 - Biggest drops (absolute dollar value)
  // 2 - Biggest drops (as a percentage)
  // 3 - Popular products: all
  // 4 - Popular products: deals only
  const types = [0, 1, 2];

  return (
    <div id="pop">
      <div className="row mt-3">
        <div className="col">
          <h4>
            Popular Products
          </h4>
        </div>
      </div>
      <div className="row">
          <div className="col">
              <div className="border-top"/>
          </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <p>
            This feature is under construction, please check back later for updates.
          </p>
          <p>
            Meanwhile you can <Link to="/search">search</Link> thousands of products and browse the <Link to="/top">Top Price Drops</Link>!
          </p>
        </div>
      </div>
    </div>
  );
}
