import axios from "axios";
import { useEffect, useState } from 'react';
import { useQuery } from "react-query";
import { useNonIsoLocale } from './Locale'

// Use React Query, but here are some posts on fetching data with React:
// https://beta.reactjs.org/learn/you-might-not-need-an-effect#fetching-data
// https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
// https://www.developerway.com/posts/how-to-fetch-data-in-react

// TODO: use firebaseConfig instead
const bucket = "winged-record-376000.appspot.com";
const baseUrl = "https://storage.googleapis.com";
const basePath = "/data";
const profile = process.env.NODE_ENV !== "production";

export function getUriPrefix() {
    if (process.env.NODE_ENV === "production") {
        return `${baseUrl}/${bucket}`;
    } else {
        return basePath;
    }
}

export function useIndexAndCategoriesData() {
    const data = [];
    data.push(useIndex());
    data.push(useCategories());
    return combineData(data);
}

export function useProductData(productId) {
    const data = [];
    data.push(useIndex());
    data.push(useCategories());
    data.push(useOptions());
    data.push(useProduct(productId));
    data.push(useInfo());
    return combineData(data);
}

export function useInfo() {
    const prefix = useUriPrefix(false);
    return useData(`${prefix}/info.json`, "info");
}

export function useIndex() {
    const prefix = useUriPrefix(true);
    return useData(`${prefix}/index.json`, "index");
}

export function useCategories() {
    const prefix = useUriPrefix(true);
    return useData(`${prefix}/categories_flattened.json`, "categories_flattened");
}

export function useOptions() {
    const prefix = useUriPrefix(true);
    return useData(`${prefix}/options.json`, "options");
}

export function useProduct(productId) {
    const prefix = useUriPrefix(true);
    return useData(`${prefix}/products/${productId.toLowerCase()}.json`, `product_${productId}`);
}

function useUriPrefix(withLocale) {
    const locale = useNonIsoLocale();
    if (withLocale) {
        return `${getUriPrefix()}/json/${locale}`;
    } else {
        return `${getUriPrefix()}/json`;
    }
}

function useData(url, key) {
    var [start, setStart] = useState(0);

    const data = useQuery(
        key,
        async () => {
            if (profile) {
                setStart(Date.now());
            }
            
            const { data } = await axios.get(url);
            return data;
        },{
            cacheTime: Infinity,
            staleTime: Infinity,
            refetchOnWindowFocus: false
        }
    );

    useEffect(() => {
        if (!profile || data.isLoading || start === 0) {
            return;
        } else {
            console.info(`Time elapsed downloading ${url}: ${Date.now() - start}ms`);
        }
    }, [data.isLoading]);

    return data;
}

function combineData(data) {
    const errors = [];
    const ret = [
        false, // [0]: isLoading
        false, // [1]: isError
        null,  // [2]: error
               // [3..n]: data
    ];

    data.forEach((data) => {
        if (data.isError) {
            errors.push(data.error);
        }

        ret[0] |= data.isLoading;
        ret[1] |= data.isError;
        ret.push(data.data);
    });

    if (ret[1]) {
        ret[2] = new AggregateError(errors);
    }

    return ret;
}
