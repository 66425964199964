export function getReferralUrl(productCode, variantCode = null, lang = null) {
    if (lang === null) {
        lang = "en";
    }

    var url;
    if (variantCode) {
        url = `https://www.canadiantire.ca/${lang}/pdp/pcode-${productCode}.${variantCode}.html`;
    } else {
        url = `https://www.canadiantire.ca/${lang}/pdp/pcode-${productCode}.html`;
    }
    
    return url; //getSovrnReferralUrl(url);
}

function getSovrnReferralUrl(productUrl) {
    // https://developer.sovrn.com/reference/building-monetized-urls
    // https://redirect.viglink.com/?key=a3ab9ec29227da128bb0641771aa6824&u=https%3a%2f%2fwww.sportchek.ca&cuid=NGSP2Z638207072045759292Z18ca9464c3fd195253a979d89dc9267c
    const key = "a2eb82a972ed18691389f890b1ea4160";
    const url = encodeURIComponent(productUrl);
    return `http://redirect.viglink.com/?key=${key}&u=${url}`;
}
