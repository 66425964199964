import { React } from 'react';
import { useParams } from "react-router-dom";
import { useIndexAndCategoriesData } from "../DataFetcher";
import { translate, useLocale } from '../Locale'
import { ProductList } from '../components/ProductList';

export default function Category(props) {
    return (
        <div id="categories">
            <CategoryImpl />
        </div>
    );
}

function CategoryImpl(props) {
    const locale = useLocale();
    const [isLoading, isError, error, index, categories] = useIndexAndCategoriesData();
    const { categoryId } = useParams();

    if (isError) {
        throw error;
    }

    if (isLoading) {
        const title = translate("category-loading-title", locale);
        const subtitle = translate("category-loading-subtitle", locale);
        return <ProductList title={title} subtitle={subtitle} loading={true} products={null} categories={null} />;
    }

    const category = categories[categoryId];
    if (!category) {
        const title = translate("category-invalid-title", locale);
        const subtitle = translate("category-invalid-subtitle", locale);
        return <ProductList title={title} subtitle={subtitle} loading={false} products={null} categories={null} />;
    }

    const products = getCategoryProducts(index, categoryId);
    if (products.length === 0) {
        const title = category.name;
        const subtitle = translate("category-no-results", locale);
        return <ProductList title={title} subtitle={subtitle} loading={false} products={null} categories={null} />;
    } 

    const title = category.name;
    const subtitle = null;
    return <ProductList title={title} subtitle={subtitle} products={products} categories={categories} />;    
}

function getCategoryProducts(index, categoryId) {
    const products = [];

    const id = categoryId.toLowerCase();
    for (const [code, value] of Object.entries(index)) {
        if (value.category && value.category.toLowerCase() === id) {
            products.splice(0, 0, { code, ...value });
        }
    }

    return products;
}
