import { React, useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import { Translate, translate, useLocale } from '../Locale';
import { Spinner } from './Spinner';

import { usePageItems, ItemPagination } from './Pagination'
import { ProductListImpl } from './ProductListImpl';
import { ProductGridImpl } from './ProductGridImpl';

// 0 - Lowest prices on record
// 1 - Biggest drops (absolute dollar value)
// 2 - Biggest drops (as a percentage)
// 3 - Popular products: all
// 4 - Popular products: deals only
// const types = [0, 1, 2];

import { DiscountToolbarDropdown, discountValues } from './Toolbars';

const minPriceDropValues = ['any', 'low', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '75', '90'];
const sortValues = discountValues;
const labelWidth = "135px";

export function ProductGrid(props) {
    const title = props.title;
    const subtitle = props.subtitle;
    const loading = props.loading;
    const products = props.products;
    const categories = props.categories;
    
    // Search parameters
    const [department, category, setDepartment, setCategory] = useSearchParamsDepartmentAndCategory(categories);
    const [minPriceDrop, setMinPriceDrop] = useSearchParam('d', minPriceDropValues);
    const [orderBy, setOrderBy] = useSearchParam('o', sortValues);

    var toolbar;
    var dropdowns;
    var results;
    
    if (loading) {
        toolbar = null;
        dropdowns = null;
        results = <Spinner />;
    } else if (products === null || categories === null) {
        toolbar = null;
        dropdowns = null;
        results = null;
    } else {
        const filtered1 = filterByCategories(products, department, category);
        const filtered2 = filterByMinPriceDrop(filtered1, minPriceDrop);
        const sorted = sortProducts(filtered2, orderBy);

        toolbar = <DiscountToolbarDropdown orderBy={orderBy} setOrderBy={setOrderBy} />;

        dropdowns = (
            <>
                <DepartmentAndCategory
                    categories={categories}
                    department={department} setDepartment={setDepartment}
                    category={category} setCategory={setCategory} />

                <MinPriceDrop minPriceDrop={minPriceDrop} setPriceDrop={setMinPriceDrop} />
            </>

        );

        results = <Results products={sorted} categories={categories} />;
    }

    return (
        <div id="productGrid">
            <div className="row mt-3">
                <div className="col-9">
                    <h2>
                        { title }
                    </h2>
                </div>
                <div className="col-3">
                    { toolbar }
                </div>
            </div>

            <div className={subtitle ? "row mt-3" : "d-none"}>
                <div className="col">
                    <p>
                        { subtitle }
                    </p>
                </div>
            </div>

            <div className={dropdowns ? "row mt-3" : "d-none"}>
                <div className="col">
                    { dropdowns }
                </div>
            </div>

            <div className="row mt-3">
                <div className="col">
                    { results }
                </div>
            </div>
        </div>
    );
}

function DepartmentAndCategory(props) {
    const locale = useLocale();
    const [departments, setDepartments] = useState(null);
    const [categories, setCategories] = useState({});

    const allCategories = props.categories;
    const department = props.department;
    const setDepartment = props.setDepartment;
    const category = props.category;
    const setCategory = props.setCategory;

    useEffect(() => {
        // Load all departments
        const departments = filterCategories("ROOT");
        setDepartments(departments);
    }, []);

    useEffect(() => {
        // Lazy-load categories under a department
        if (!department || department === "none")
            return;

        // Already loaded?
        if (categories[department])
            return;
        
        const cats = filterCategories(department);
        setCategories({...categories, [department]: cats });
    }, [department]);

    function filterCategories(parentId) {
        const cats = [];
        for (const [code, value] of Object.entries(allCategories)) {
            if (value.parentId === parentId) {
                cats.splice(0, 0, { code, ...value });
            }
        }
        return cats;
    }

    function handleDepartmentChange(event) {
        setDepartment(event.target.value);
    }

    function handleCategoryChange(event) {
        setCategory(event.target.value);
    }

    function sort(categories) {
        return categories.sort((a, b) => (a.name > b.name) ? 1 : -1);
    }

    var departmentsJsx = null;
    if (departments) {
        const sorted = sort(departments);
        const optionsJsx = sorted.map((category, i) =>
            <option key={category.code} value={category.code}>{category.name}</option>
        );

        const selectedValue = department ? department : "none";
        departmentsJsx = (
            <div className="input-group">
                <div className="input-group-text" style={{width: labelWidth}} id="btnGroup1">
                    <Translate string="product-list-department-and-category-dept" />
                </div>
                <select className="form-select" aria-label={translate("product-list-department-and-category-dept-select", locale)} aria-describedby="btnGroup1" value={selectedValue} onChange={handleDepartmentChange}>
                    <option value="none"><Translate string="product-list-department-and-category-all-depts" /></option>
                    { optionsJsx }
                </select>
            </div>
        );
    }

    var categoriesJsx = null;
    if (categories && categories[department]) {
        const sorted = sort(categories[department]);
        const optionsJsx = sorted.map((category) =>
            <option key={category.code} value={category.code}>{category.name}</option>
        );

        const selectedValue = category ? category : "none";
        categoriesJsx = (
            <div className="input-group mt-2">
                <div className="input-group-text" style={{width: labelWidth}} id="btnGroup2">
                    <Translate string="product-list-department-and-category-cat" />
                </div>
                <select className="form-select" aria-label={translate("product-list-department-and-category-cat-select", locale)} aria-describedby="btnGroup2" value={selectedValue} onChange={handleCategoryChange}>
                    <option value="none"><Translate string="product-list-department-and-category-all-cats" /></option>
                    { optionsJsx }
                </select>
            </div>
        );
    }

    return (
        <>
            {departmentsJsx}
            {categoriesJsx}
        </>
    );
}

function MinPriceDrop(props) {
    const locale = useLocale();

    const minPriceDrop = props.minPriceDrop;
    const setPriceDrop = props.setPriceDrop;

    function handleChange(event) {
        setPriceDrop(event.target.value);
    }

    const minPriceDropStrings = [...minPriceDropValues];
    minPriceDropStrings[0] = translate("product-grid-min-price-drop-any", locale);
    minPriceDropStrings[1] = translate("product-grid-min-price-drop-low", locale);
    for (let i = 2; i < minPriceDropStrings.length; i++) {
        minPriceDropStrings[i] += '%';
    }
    
    const optionsJsx = minPriceDropValues.map((value, i) =>
        <option key={value} value={value}>{minPriceDropStrings[i]}</option>
    );

    const selectedValue = minPriceDrop ? minPriceDrop : minPriceDropValues[0];
    const select = (
        <div className="input-group mt-2">
            <div className="input-group-text" style={{width: labelWidth}} id="btnGroup3">
                <Translate string="product-grid-min-price-drop-label" />
            </div>
            <select className="form-select" aria-label={translate("product-grid-min-price-drop-select", locale)} aria-describedby="btnGroup3" value={selectedValue} onChange={handleChange}>
                { optionsJsx }
            </select>
        </div>
    );

    return select;
}

function Results(props) {
    const products = props.products;
    const categories = props.categories;

    const maxItemsPerPage = 60;
    const pageProducts = usePageItems(products, maxItemsPerPage);

    return (
        <>
            {/* <ProductListImpl products={pageProducts} categories={categories} /> */}
            <ProductGridImpl products={pageProducts} categories={categories} />
            <ItemPagination items={products} maxItemsPerPage={maxItemsPerPage}/>
        </>
    );
}

function filterByCategories(items, catL1, catL2) {
    if (!catL1 || catL1 === "none") {
        return items;
    }

    const itemsCatL1 = [];
    items.forEach(item => {
        if (item.l1c.includes(catL1)) {
            itemsCatL1.push(item);
        }
    });

    if (!catL2 || catL2 === "none") {
        return itemsCatL1;
    }

    const itemsCatL2 = [];
    itemsCatL1.forEach(item => {
        if (item.l2c.includes(catL2)) {
            itemsCatL2.push(item);
        }
    });

    return itemsCatL2;
}

function filterByMinPriceDrop(items, minPriceDrop) {
    if (minPriceDrop === minPriceDropValues[0]) {
        return items;
    }

    if (minPriceDrop === minPriceDropValues[1]) {
        const filteredItems = [];
        items.forEach(item => {
            if (item.disc[0] === 1) {
                filteredItems.push(item);
            }
        });
        return filteredItems;
    }

    const filteredItems = [];
    const discount = parseInt(minPriceDrop);
    items.forEach(item => {
        if (item.disc[2] >= discount) {
            filteredItems.push(item);
        }
    });
    return filteredItems;
}

function sortProducts(products, sortBy) {

    function sort(i, asc) {
        return products.sort((a, b) => {
            if (a.disc[i] === b.disc[i])
                return 0;

            if (!asc && a.disc[i] > b.disc[i])
                return -1;
            
            if (asc && a.disc[i] < b.disc[i])
                return -1;

            return 1;
        });
    }

    // "disc": [ 1.0, 145.00, 27.0 ]
    // "disc": [ low,    val,  pct ]
    if (sortBy === 'pct-asc') {
        sort(2, true);
    } else if (sortBy === 'pct-dsc') {
        sort(2, false);
    } else if (sortBy === 'val-asc') {
        sort(1, true);
    } else {
        sort(1, false);
    }

    return products;
}

function useSearchParamsDepartmentAndCategory(categories) {
    const [searchParams, setSearchParams] = useSearchParams();

    var department = searchParams.get("c1");
    department = categories && categories[department] ? department : "none";

    var category = searchParams.get("c2");
    category = categories && categories[category] ? category : "none";

    function setDepartment(c1) {
        searchParams.set("c1", c1);
        searchParams.set("c2", "none");
        searchParams.set("p", 1);
        setSearchParams(searchParams);
    }

    function setCategory(c2) {
        searchParams.set("c2", c2);
        searchParams.set("p", 1);
        setSearchParams(searchParams);
    }

    return [department, category, setDepartment, setCategory];
}

function useSearchParam(paramName, validValues) {
    const [searchParams, setSearchParams] = useSearchParams();

    var param = searchParams.get(paramName);
    if (!param || !validValues.includes(param)) {
        param = validValues[0];
    }

    function setParam(param) {
        searchParams.set(paramName, param);
        searchParams.set("p", 1);
        setSearchParams(searchParams);
    }

    function deleteParam() {
        searchParams.delete(paramName);
    }

    return [param, setParam, deleteParam];
}
