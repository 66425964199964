import { React } from 'react';
import { Link } from "react-router-dom";
import { translate, useLocale } from "../Locale";

export function SearchBreadcrumbs(props) {
    const categories = props.categories;
    const product = props.product;
    const showLink = props.showLink;

    // Product comes from the search index
    const categoryId = product.category;
    const manufacturer = product.manufacturer;

    return (
        <Breadcrumbs categories={categories} categoryId={categoryId} manufacturer={manufacturer} showLink={showLink} compactOnly={true} />
    );
}

export function ProductBreadcrumbs(props) {
    const categories = props.categories;
    const product = props.product;
    const showLink = props.showLink;

    // Product has an array of categories, pick the first one if available
    var categoryId;
    if (product.categories && product.categories.length > 0) {
        categoryId = product.categories[0];
    } else {
        categoryId = null;
    }
    const manufacturer = product.manufacturer;

    return (
        <Breadcrumbs categories={categories} categoryId={categoryId} manufacturer={manufacturer} showLink={showLink} compactOnly={true} />
    );
}

function Breadcrumbs(props) {
    const locale = useLocale();

    const categories = props.categories;
    const categoryId = props.categoryId;
    const manufacturer = props.manufacturer;
    const showLink = props.showLink;
    const compactOnly = props.compactOnly;

    const breadcrumbsFull = getBreadcrumbObjects(categories, categoryId, manufacturer, locale);
    if (breadcrumbsFull.length === 0)
        return null;

    const breadcrumbLast = breadcrumbsFull.slice(-1)[0];
    const breadcrumbsCompCount = breadcrumbLast.isCategory ? 1 : 2;
    const breadcrumbsComp = breadcrumbsFull.slice(-1 * breadcrumbsCompCount);
    
    if (compactOnly) {
        return (
            <>
                <BreadcrumbsImpl showLink={showLink} breadcrumbs={breadcrumbsComp} className="" />
            </>
        );
    } else {
        return (
            <>
                <BreadcrumbsImpl showLink={showLink} breadcrumbs={breadcrumbsFull} className="d-none d-xl-block" />
                <BreadcrumbsImpl showLink={showLink} breadcrumbs={breadcrumbsComp} className="d-xl-none"/>
            </>
        );
    }
}

function BreadcrumbsImpl(props) {
    const breadcrumbObjects = props.breadcrumbs;
    const showLink = props.showLink;
    const className = props.className;

    const breadcrumbs = breadcrumbObjects.map((breadcrumb) =>
        <li key={breadcrumb.id} className="breadcrumb-item">
            <BreadcrumbLink breadcrumb={breadcrumb} showLink={showLink} />
        </li>
    );

    return (
        <>
            <nav aria-label="breadcrumb" style={{"--bs-breadcrumb-divider": "'»'"}} className={className}>
                <ol className="breadcrumb">
                    {breadcrumbs}
                </ol>
            </nav>
        </>
    );
}

export function BreadcrumbLink(props) {
    const breadcrumb = props.breadcrumb;
    const showLink = props.showLink;

    var link;
    if (breadcrumb.isCategory)
        link = `/category/${breadcrumb.id}`;
    else
        link = `/manufacturer/${breadcrumb.id}`;

    if (showLink)
        return <Link to={link}>{breadcrumb.name}</Link>
    else
        return <>{breadcrumb.name}</>
}

export function getBreadcrumbObjects(categories, categoryId, manufacturer, locale) {
    var breadcrumbs;

    if (manufacturer) {
        breadcrumbs = [{
            id: manufacturer,
            name: manufacturer,
            isCategory: false
        }];
    } else {
        breadcrumbs = [];
    }

    if (!categories || categories.length === 0) {
        return breadcrumbs;
    }

    while (categoryId) {
        const category = categories[categoryId];
        if (!category || !category.parentId) {
            break;
        }
        
        breadcrumbs.splice(0, 0, {
            id: categoryId,
            name: category.name ? category.name : translate("na", locale),
            isCategory: true
        });

        categoryId = category.parentId;
    }

    return breadcrumbs;
}
