import { React, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { translate, useLocale } from '../Locale'

export function ItemPagination(props) {
    const locale = useLocale();
    const pageCount = getPageCount(props.items, props.maxItemsPerPage);
    const [pageNumber, isPageNumberInvalid, setPageNumber] = usePageNumber();

    useEffect(() => {
        if (isPageNumberInvalid(pageCount, pageNumber)) {
            setPageNumber(pageCount, 1);
        }
    }, [pageNumber]);

    // Do not render this component if there's only one page
    // to display
    if (pageCount == 1) {
        return null;
    }

    const pageNumbers = getPageNumbers(pageCount, pageNumber);

    const pages = pageNumbers.map((currentPage, index) => {
        var className;
        var description;

        if (currentPage === null) {
            className = "page-item disabled";
            description = '\u2026'; // Horizontal Ellipsis
            currentPage = 1;
        } else if (currentPage === pageNumber) {
            className = "page-item active";
            description = currentPage;
        } else {
            className = "page-item";     
            description = currentPage;
        }

        function handleClick(event) {
            event.preventDefault();
            setPageNumber(pageCount, currentPage);
        }

        return (
            <li key={index} className={className}>
                <a className="page-link" href="" onClick={handleClick}>
                    {description}
                </a>
            </li>
        );
    });

    function handlePrevClick(event) {
        event.preventDefault();
        if (pageNumber === 1) {
            return;
        }
        setPageNumber(pageCount, pageNumber - 1);
    }

    function handleNextClick(event) {
        event.preventDefault();
        if (pageNumber === pageCount) {
            return;
        }
        setPageNumber(pageCount, pageNumber + 1);
    }

    // Alternative 'Prev' labels:
    // <Translate string="pagination-prev" />
    // {'\u25C4'} // Black Left-Pointing Pointer

    // Alternative 'Next' labels:
    // <Translate string="pagination-next" />
    // {'\u25BA'} // Black Right-Pointing Pointer

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <div className="border-top"/>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col">
                    <nav aria-label={translate("pagination-navigation", locale)}>
                        <ul className="pagination justify-content-center m-0">
                            <li className={`page-item ${pageNumber === 1 ? "disabled" : ""}`}>
                                <a className="page-link" href="" aria-label={translate("pagination-prev", locale)} onClick={handlePrevClick}>
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>

                            { pages }

                            <li className={`page-item ${pageNumber === pageCount ? "disabled" : ""}`}>
                                <a className="page-link" href="" aria-label={translate("pagination-next", locale)} onClick={handleNextClick}>
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
}

export function usePageItems(items, maxItemsPerPage) {
    const [pageNumber] = usePageNumber();

    var beg = (pageNumber - 1) * maxItemsPerPage;
    var end = beg + maxItemsPerPage;
    if (end > items.length) {
        end = items.length;
    }
    return items.slice(beg, end);
}

function usePageNumber() {
    const [searchParams, setSearchParams] = useSearchParams();

    var pageNumber = parseInt(searchParams.get("p"));
    if (!pageNumber || pageNumber < 1) {
        pageNumber = 1;
    }

    function isNumber(n) {
        return !isNaN(n) && isFinite(n);
    }

    function isPageNumberInvalid(pageCount, pageNumber) {
        if (!isNumber(pageCount)) {
            console.error("'pageCount' is not a number");
            return true;
        }
        if (!isNumber(pageNumber)) {
            console.error("'pageNumber' is not a number");
            return true;
        }
        return pageCount < pageNumber || pageNumber < 1;
    }

    function setPageNumber(pageCount, pageNumber) {
        if (isPageNumberInvalid(pageCount, pageNumber)) {
            searchParams.set("p", 1);
        } else {
            searchParams.set("p", pageNumber);
        }
        setSearchParams(searchParams);
    }

    function deletePageNumber() {
        searchParams.delete("p");
    }

    return [pageNumber, isPageNumberInvalid, setPageNumber, deletePageNumber];
}

function getPageCount(items, maxItemsPerPage) {
    var pageCount = Math.trunc(items.length / maxItemsPerPage);
    if (pageCount === 0 || items.length % maxItemsPerPage !== 0) {
        pageCount++;
    }
    return pageCount;
}

function getPageNumbers(pageCount, pageNumber) {
    var elements;
    
    if (pageCount <= 7) {
        elements = Array.from(Array(pageCount + 1).keys());
        elements.splice(0, 1);
    } else {
        elements = [];

        const left = Math.min(pageNumber - 1, 3);
        const right = Math.min(pageCount - pageNumber, 3);
        
        const rightCount = right + (3 - left);
        const leftCount = left - (right - 3);

        const beg = pageNumber - leftCount;
        const end = pageNumber + rightCount;
        for (var i = beg; i <= end; i++) {
            elements.push(i);
        }

        if (elements[0] !== 1) {
            elements[0] = 1;
            elements[1] = null;
        }

        if (elements[6] !== pageCount) {
            elements[5] = null;
            elements[6] = pageCount;
        }
    }

    return elements;
}
