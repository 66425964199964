import { React } from 'react';
import { Translate, useLanguage } from '../Locale'
import { Link } from "react-router-dom";

export default function TermsOfService(props) {
    return (
        <div id="tos">
            <div className="row mt-3">
                <div className="col">
                    <h2>
                        <Translate string="terms-title"/>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="border-top"/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    {getTOS()}
                </div>
            </div>
        </div>
    );
}

function getTOS() {
    const lang = useLanguage();
    if (lang === "en") {
        return getEnglishTOS();
    } else {
        return getFrenchTOS();
    }
}

function getEnglishTOS() {
    return (
        <>
            <p>
                Last updated: 2023-07-03
            </p>
            <p>
                This website offers a free service, and therefore, we do not provide any warranty or guarantee regarding the service or uptime. By using this site, you acknowledge that you have read and agreed to these terms, and you do so at your own risk.
            </p>
            <p>
                Some of the content displayed on this site is sourced from Canadian Tire Corporation, Limited. This content is provided &apos;as is&apos; and is subject to change or removal at any time.
            </p>
            {/*
            <p>
                Tire Spy uses Skimlinks, a content monetisation platform designed to provide a means for sites to earn advertising fees by advertising and linking to e-commerce websites.
            </p>
            */}
            <p>
                Canadian Tire and the Canadian Tire logo are trademarks of Canadian Tire Corporation, Limited. or its affiliates.
            </p>
            <p>
                <Translate string="product-price-tooltip"/>
            </p>
            <p>
                <Link to="/privacy">View Privacy Policy</Link>
            </p>
        </>
    );
}

function getFrenchTOS() {
    return getEnglishTOS();
}
