import { React, useState } from 'react';
import { Translate, translate, useLocale } from '../Locale'
import { Alert } from './Alert';
import { logException } from '../FirebaseFunctions';

export function ContactInfo(props) {
    return (
        <>
            {/* TWITTER */}
            <div className="row mt-3">
                <div className="col">
                    <h4>
                        <Translate string="contact-twitter-title"/>
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="border-top"/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p>
                        <Translate string="contact-twitter-description"/>
                        &nbsp;
                        <b>
                            <a href="http://twitter.com/TireSpy" target="_blank" rel="noreferrer">@TireSpy</a>
                        </b>
                    </p>
                </div>
            </div>

            {/* CONTACT FORM */}
            <div className="row mt-3">
                <div className="col">
                    <h4>
                        <Translate string="contact-form-title"/>
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="border-top"/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p>
                        <Translate string="contact-form-description"/>
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ContactForm />
                </div>
            </div>
        </>
    );
}

function ContactForm(props) {
    const initialState = {
        access_key: "d13048a6-d5d3-4a53-92b4-3183ece46435",
        subject: "New message from TireSpy.ca",
        botcheck: false,
        name: "",
        email: "",
        message: ""
    };

    const locale = useLocale();
    const [state, setState] = useState(initialState);
    const [alert, setAlert] = useState({ show: false, type: "", body: "" });

    const namePlaceholder = ""; //"Joe Smith";
    const emailPlaceholder = ""; //"email@example.com";
    const messagePlaceholder = ""; //"Please let us how we can help you.";

    function handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setState({ ...state, [name]: value });
    }

    function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        hideAlert();

        const form = document.getElementById("form");
        const isValid = form.checkValidity();
        if (isValid) {
            submitForm();
        } else {
            form.classList.add('was-validated');
        }
    }

    function submitForm() {
        var json;
        try {
            json = JSON.stringify(state);
        } catch (error) {
            const success = false;
            const message = error.message;
            showAlert(success, message);
            logException(`Error stringify-ing state: ${error.message}`, true);
            return;
        }

        disableControls(true);
        fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then(async (response) => {
            const json = await response.json();
            if (response.status == 200) {
                const success = true;
                const message = translate("contact-form-success", locale);
                showAlert(success, message);
                resetForm();
            } else {
                const success = false;
                const message = (
                    <>
                        <Translate string="contact-form-error" />
                        <hr />
                        {json.message}
                    </>
                );
                showAlert(success, message);
                logException(`Error sending message: ${json.message}`, true);
            }
        }).catch((error) => {
            const success = false;
            const message = (
                <>
                    <Translate string="contact-form-error" />
                    <hr />
                    {error.message}
                </>
            );
            showAlert(success, message);
            logException(`Error sending message: ${error.message}`, true);
        }).then(() => {
            disableControls(false);
        });
    }

    function resetForm() {
        const form = document.getElementById("form");
        form.classList.remove('was-validated');
        setState(initialState);
    }

    function disableControls(disabled) {
        const controls = document.querySelectorAll('.control');
        Array.from(controls).forEach(control => {
            control.disabled = disabled;
        });
    }

    function showAlert(success, message) {
        setAlert({
            show: true,
            type: success ? "success" : "danger",
            body: message
        });
    }

    function hideAlert() {
        setAlert({ ...alert, show: false });
    }

    return (
        <form id="form" className="needs-validation" noValidate onSubmit={handleSubmit}>
            <input type="hidden" name="access_key" value={state.access_key} />
            <input type="hidden" name="subject" value={state.subject} />
            <input type="checkbox" name="botcheck" value={state.botcheck} id="" style={{ "display": "none" }} />

            <div className="row">
                <div className="col-md-12 col-lg-6 mb-3">
                    <Alert show={alert.show} type={alert.type} body={alert.body} onClose={hideAlert} />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-lg-6 mb-3">
                    <label htmlFor="name" className="form-label">
                        <Translate string="contact-form-name-label"/>
                    </label>
                    <input
                        className="control form-control"
                        placeholder={namePlaceholder}
                        id="name"
                        name="name"
                        type="text"
                        required
                        value={state.name}
                        onChange={handleInputChange} />
                    <div className="invalid-feedback">
                        <Translate string="contact-form-name-invalid"/>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-lg-6 mb-3">
                    <label htmlFor="email" className="form-label">
                        <Translate string="contact-form-email-label"/>
                    </label>
                    <input
                        className="control form-control"
                        placeholder={emailPlaceholder}
                        id="email"
                        name="email"
                        type="email"
                        required
                        value={state.email}
                        onChange={handleInputChange} />
                    <div className="invalid-feedback">
                        <Translate string="contact-form-email-invalid"/>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-lg-6 mb-3">
                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">
                            <Translate string="contact-form-message-label"/>
                        </label>
                        <textarea
                            rows="5"
                            className="control form-control"
                            placeholder={messagePlaceholder}
                            id="message"
                            name="message"
                            required
                            value={state.message}
                            onChange={handleInputChange} />
                        <div className="invalid-feedback">
                            <Translate string="contact-form-message-invalid"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-lg-6 mb-3">
                    <div className="mb-3">
                        <button className="control btn btn-primary" type="submit">
                            <Translate string="contact-form-button-label"/>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}
