import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

import {
    QueryClient,
    QueryClientProvider
} from "react-query";

import Root, { loader as rootLoader } from "./routes/Root";

import Home from "./routes/Home";
import About from "./routes/About";
import Features from "./routes/Features";
import TermsOfService from "./routes/TermsOfService";
import Privacy from "./routes/Privacy";
import SupportUs from "./routes/SupportUs";
import Help from "./routes/Help";
import Search from "./routes/Search";
import PopularProducts from "./routes/PopularProducts";
import TopPriceDrops from "./routes/TopPriceDrops";
import Product from "./routes/Product";
import Manufacturer from "./routes/Manufacturer";
import Category from "./routes/Category";
import Unsubscribe from "./routes/Unsubscribe";
import Referral from "./routes/Referral";

import ErrorPage from "./ErrorPage";

import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        loader: rootLoader,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <Home />
                    },
                    {
                        path: "about",
                        element: <About />
                    },
                    {
                        path: "features",
                        element: <Features />
                    },
                    {
                        path: "tos",
                        element: <TermsOfService />
                    },
                    {
                        path: "privacy",
                        element: <Privacy />
                    },
                    {
                        path: "support-us",
                        element: <SupportUs />
                    },
                    {
                        path: "help",
                        element: <Help />
                    },
                    {
                        path: "search",
                        element: <Search />
                    },
                    {
                        path: "pop",
                        element: <PopularProducts />
                    },
                    {
                        path: "top",
                        element: <TopPriceDrops />
                    },
                    {
                        path: "product/:productId",
                        element: <Product />
                    },
                    {
                        path: "manufacturer/:manufacturerId",
                        element: <Manufacturer />
                    },
                    {
                        path: "category/:categoryId",
                        element: <Category />
                    },
                    {
                        path: "unsubscribe/:base64String",
                        element: <Unsubscribe />
                    },
                    {
                        path: "r/:base64String",
                        element: <Referral />
                    }
                ]
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
