import { React, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useLocale, useLanguage, Translate, translate } from '../Locale';
import { logException, logOpenReferral } from '../FirebaseFunctions'
import { getReferralUrl } from '../ReferralUtils'
import { Logo as LogoComponent } from '../components/Logo';

function useReferralParams() {
    const { base64String } = useParams();
    const language = useLanguage();
    const ret = [false, null, null, null, null, null];

    var json;
    try {
        json = atob(base64String);
    } catch (error) {
        const message = `useReferralParams: unable to decode base64 string '${base64String}': ${error.message}`;
        logException(message, true);
        return ret;
    }

    var params;
    try {
        params = JSON.parse(json);
    } catch (error) {
        const message = `useReferralParams: unable to parse JSON '${json}': ${error.message}`;
        logException(message, true);
        return ret;
    }

    // productCode is the only required parameter
    if (!params.productCode) {
        const message = `useReferralParams: productCode is undefined: '${json}'`;
        logException(message, true);
        return ret;
    }

    ret[0] = true;
    ret[1] = getReferralUrl(params.productCode, params.variantCode, language);
    ret[2] = params.productCode;
    ret[3] = params.variantCode;
    ret[4] = params.currentPrice;
    ret[5] = `email;${params.key}`; //`email=${params.emailTo};key=${params.key}`;
    return ret;
}

export default function Referral() {
    const locale = useLocale();
    const [success, url, productCode, variantCode, price, source] = useReferralParams();

    useEffect(() => {
        if (success) {
            logOpenReferral(productCode, variantCode, price, source);
            location.href = url;
        }
    }, []);

    var headJsx;
    var bodyJsx;
    if (success) {
        headJsx = <Logo productCode={productCode} variantCode={variantCode} />;
        bodyJsx = (
            <>
                <p className="mt-3">
                    <Translate string="referral-line1" />
                </p>
                <p>
                    <Button url={url} text={translate("referral-button", locale)}/>
                </p>
                <p>
                    <Translate string="referral-line2" />
                </p>
            </>
        );
    } else {
        headJsx = <Logo />;
        bodyJsx = (
            <>
                <p className="mt-3">
                    <Translate string="referral-error-line1" />
                </p>
                <p>
                    <Translate string="referral-error-line2" />
                </p>
                <p>
                    <Button url="/" text={translate("referral-error-button", locale)}/>
                </p>
            </>
        );
    }

    return (
        <div id="referral" className="mt-3">
            { headJsx }
            { bodyJsx }
        </div>
    );
}

function Logo(props) {
    const locale = useLocale();
    const productCode = props.productCode;
    const variantCode = props.variantCode;

    var url;
    if (productCode && variantCode) {
        url = `/product/${productCode}?variant=${variantCode}`;
    } else if (productCode) {
        url = `/product/${productCode}`;
    } else {
        url = "/"
    }

    return <LogoComponent url={url}/>
}

function Button(props) {
    const url = props.url;
    const text = props.text;
    return (
        <a href={url} className="btn btn-light btn-outline-primary" target="_self" rel="noreferrer">
            { text }
        </a>
    );
}
