import { React } from 'react';
import { Translate, useLanguage } from '../Locale'
import { Link } from "react-router-dom";

export default function Privacy(props) {
    return (
        <div id="tos">
            <div className="row mt-3">
                <div className="col">
                    <h2>
                        <Translate string="privacy-title"/>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="border-top"/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    {getPolicy()}
                </div>
            </div>
        </div>
    );
}

function getPolicy() {
    const lang = useLanguage();
    if (lang === "en") {
        return getEnglishPolicy();
    } else {
        return getFrenchPolicy();
    }
}

function getEnglishPolicy() {
    return (
        <>
            <p>
                Last updated: 2024-04-28
            </p>
            <p>
                We strive to minimize the collection of personal data from our users. However, in order to provide price alerts and other site-related messages, we typically require your email address. If you prefer not to be contacted, please refrain from providing us with your email address. Rest assured that we do not sell or share your email address outside our company. Upon request, we will promptly remove it from our database. Contact us to request data deletion. It&apos;s a simple process.
            </p>
            <p>
                Nevertheless, there are a few ways in which we utilize third-party services to support and maintain this website:
            </p>
            <ul>
                {/*
                <li>
                    <b>Affiliate Marketing.</b> When you make a purchase through our site, Canadian Tire/Skimlinks pay us a percentage of each sale. This does not affect the price you pay and helps us sustain this website. We neither track nor have the capability to track individual purchases.
                </li>
                */}
                <li>
                    <b>Advertising.</b> We embed scripts from one or more ad networks in our pages, which display ads that may be relevant to the viewer. If you prefer to opt out, you can use an ad blocker and consider <Link to="/support-us">supporting us through a donation</Link>.
                </li>
                <li>
                    <b>Analytics.</b> Third-party companies analyze our web traffic for internal reporting and server monitoring purposes. We only gather anonymized, aggregated data and do not track individual website activity.
                </li>
            </ul>

            <p>
                Please note that ad networks and analytics companies may gather more of your personal data as they strive to create viewer profiles for targeted advertising and web traffic analysis. The complete list of our vendors is provided below.
            </p>

            <p>
                <Link to="/tos">View Terms of Service</Link>
            </p>

            {getVendorList()}
        </>
    );
}

function getFrenchPolicy() {
    return getEnglishPolicy();
}

function getVendorList() {
    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <h4>
                        Full Vendor List
                    </h4>
                </div>
            </div>
            
            <div className="row">
                <div className="col">
                    <div className="border-top"/>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col">
                    {/*
                    <h5>Skimlinks</h5>
                    <p>
                        Privacy Policy: <a href="https://skimlinks.com/privacy-policies/" target="_blank" rel="noreferrer">https://skimlinks.com/privacy-policies/</a>
                        <br/>
                        Intent: Affiliate Marketing
                    </p>
                    */}

                    <h5>Google Cloud, AdSense and Analytics</h5>
                    <p>
                        Privacy Policy: <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">https://policies.google.com/privacy</a>
                        <br/>
                        Intent: Analytics, Advertising, and Cloud Platform
                    </p>

                    {/*
                    <h5>Amazon Web Services</h5>
                    <p>
                        Privacy Policy: <a href="https://aws.amazon.com/privacy" target="_blank" rel="noreferrer">https://aws.amazon.com/privacy</a>
                        <br/>
                        Intent: Cloud Platform
                    </p>
                    */}

                    <h5>Microsoft Azure</h5>
                    <p>
                        Privacy Policy: <a href="https://privacy.microsoft.com/privacystatement" target="_blank" rel="noreferrer">https://privacy.microsoft.com/privacystatement</a>
                        <br/>
                        Intent: Communication Services
                    </p>
                </div>
            </div>
        </>
    );
}
