import { React } from 'react';

import { Translate, translate, useLocale } from '../Locale'

import { ToolbarDropdown } from './ToolbarDropdown'

import Dropdown from 'bootstrap/js/dist/dropdown';

/******************************************
 * Dropdown Toolbar
 ******************************************/

//export const discountValues = ['pct-asc', 'pct-dsc', 'val-asc', 'val-dsc'];
export const discountValues = ['val-dsc', 'pct-dsc'];

export function ProductListToolbarDropdown(props) {
    const categories = props.categories;
    const orderBy = props.orderBy;
    const setOrderBy = props.setOrderBy;
    const filterBy = props.filterBy;
    const setFilterBy = props.setFilterBy;

    if (categories.length < 2) {
        return (
            <div className="btn-group float-end" role="group" aria-label="Button group with nested dropdown">
                <OrderByPriceDropdown value={orderBy} setValue={setOrderBy}/>
            </div>
        );
    } else {
        return (
            <div className="btn-group float-end" role="group" aria-label="Button group with nested dropdown">
                <OrderByPriceDropdown value={orderBy} setValue={setOrderBy}/>
                <FilterByCategoryDropdown value={filterBy} setValue={setFilterBy} categories={categories}/>
            </div>
        );
    }
}

export function DiscountToolbarDropdown(props) {
    const orderBy = props.orderBy;
    const setOrderBy = props.setOrderBy;

    return (
        <div className="btn-group float-end" role="group" aria-label="Button group with nested dropdown">
            <OrderByDiscountDropdown value={orderBy} setValue={setOrderBy}/>
        </div>
    );
}

function OrderByPriceDropdown(props) {
    const locale = useLocale();
    const value = props.value;
    const setValue = props.setValue;

    const values = [
        { value: 1, text: translate("product-list-toolbar-order-by-relevance", locale) },
        { value: 2, text: translate("product-list-toolbar-order-by-price-low-to-high", locale) },
        { value: 3, text: translate("product-list-toolbar-order-by-price-high-to-low", locale) },
        //{ value: 4, text: translate("product-list-toolbar-order-by-title", locale) }
    ];

    return <ToolbarDropdown iconClassName={"bi-sort-alpha-down"} value={value} setValue={setValue} values={values} />
}

function OrderByDiscountDropdown(props) {
    const locale = useLocale();
    const value = props.value;
    const setValue = props.setValue;

    const values = [];
    discountValues.forEach((value) => {
        values.push({
            value,
            text: translate(`product-list-toolbar-order-by-discount-${value}`, locale)
        });
    });

    return <ToolbarDropdown iconClassName={"bi-sort-alpha-down"} value={value} setValue={setValue} values={values} />
}

function FilterByCategoryDropdown(props) {
    const locale = useLocale();
    const categories = props.categories;
    const value = props.value;
    const setValue = props.setValue;

    const values = [{
        value: "none",
        text: translate("product-list-toolbar-filter-by-no-filter", locale)
    }];

    categories.forEach((category) => {
        values.push({
            value: category.code,
            text: category.name
        });
    });

    return <ToolbarDropdown iconClassName={"bi-filter"} value={value} setValue={setValue} values={values} />
}

/******************************************
 * Select Toolbar
 ******************************************/

export function ProductListToolbarSelect(props) {
    const categories = props.categories;
    const orderBy = props.orderBy;
    const setOrderBy = props.setOrderBy;
    const filterBy = props.filterBy;
    const setFilterBy = props.setFilterBy;

    if (categories.length < 2) {
        return (
            <div className="row mt-3">
                <div className="col btn-toolbar">
                    <OrderBySelect orderBy={orderBy} setOrderBy={setOrderBy} />
                </div>
            </div>
        );
    } else {
        return (
            <div className="row">
                <div className="col-md-4 mt-3">
                    <OrderBySelect orderBy={orderBy} setOrderBy={setOrderBy} />
                </div>
                <div className="col-md-8 mt-3">
                    <FilterBySelect filterBy={filterBy} setFilterBy={setFilterBy} categories={categories}/>
                </div>
            </div>
        );
    }
}

function OrderBySelect(props) {
    const locale = useLocale();
    const orderBy = props.orderBy;
    const setOrderBy = props.setOrderBy;

    function handleOrderByChange(event) {
        setOrderBy(event.target.value);
    }

    return (
        <div className="input-group">
            <div className="input-group-text" id="btnGroupOrderBy">
                <Translate string="product-list-toolbar-order-by" />
            </div>
            <select className="form-select" aria-label={translate("product-list-toolbar-order-by-select", locale)} aria-describedby="btnGroupOrderBy" value={orderBy} onChange={handleOrderByChange}>
                <option value="1"><Translate string="product-list-toolbar-order-by-relevance" /></option>
                <option value="2"><Translate string="product-list-toolbar-order-by-price-low-to-high" /></option>
                <option value="3"><Translate string="product-list-toolbar-order-by-price-high-to-low" /></option>
                <option value="4"><Translate string="product-list-toolbar-order-by-title" /></option>
            </select>
        </div>
    );
}

function FilterBySelect(props) {
    const locale = useLocale();
    const categories = props.categories;
    const filterBy = props.filterBy;
    const setFilterBy = props.setFilterBy;

    const options = categories.map((category) =>
        <option key={category.code} value={category.code}>{category.name}</option>
    );

    function handleFilterByChange(event) {
        setFilterBy(event.target.value);
    }

    return (
        <div className="input-group">
            <div className="input-group-text" id="btnGroupFilterBy">
                <Translate string="product-list-toolbar-filter-by" />
            </div>
            <select className="form-select" aria-label={translate("product-list-toolbar-filter-by-select", locale)} aria-describedby="btnGroupFilterBy" value={filterBy} onChange={handleFilterByChange}>
                <option value="none"><Translate string="product-list-toolbar-filter-by-no-filter" /></option>
                {options}
            </select>
        </div>
    );
}
