import { React } from 'react';
import { Link } from "react-router-dom";
import { useLocale, translate } from '../Locale';

export function Logo(props) {
    const locale = useLocale();
    const url = props.url ? props.url : "/";
    const alt = translate("website-name", locale);

    return <LogoWithName url={url} alt={alt}/>;
}

function LogoWithName(props) {
    const url = props.url;
    const alt = props.alt;

    return (
        <Link to={url}>
            <img
                className="me-2"
                alt={alt}
                src="/logo/logo_name_03_no_ca.png"
                height="28"/>
        </Link>
    );
}

function LogoFlagOnly(props) {
    const url = props.url;
    const alt = props.alt;

    {/*
    <Link to="/">
        <a className="navbar-brand me-2" href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-graph-down-arrow" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 11.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-1 0v2.6l-3.613-4.417a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61L13.445 11H10.5a.5.5 0 0 0-.5.5Z"/>
            </svg>
        </a>
    </Link>
    */}

    // Grey border around logo:
    // style={{"border": "var(--bs-border-width) solid var(--bs-gray-500)"}}
    return (
        <Link to={url}>
            <img
                className="me-2"
                alt={alt}
                src="/logo/logo.svg"
                height="34"
                onError={(e) => {
                    e.currentTarget.src = "/logo/logo.png";
                }}/>
        </Link>
    );
}
