import { React } from 'react';
import { useSearchParams } from "react-router-dom";
import { useSearchEngine } from "../SearchEngine";
import { translate, useLocale } from '../Locale'
import { useIndexAndCategoriesData } from "../DataFetcher";
import { ProductList } from '../components/ProductList'

export default function Search(props) {
    return (
        <div id="search">
            <SearchImpl />
        </div>
    );
}

function SearchImpl(props) {
    const locale = useLocale();
    const [searchParams] = useSearchParams();
    const [isLoading, isError, error, index, categories] = useIndexAndCategoriesData();
    const engine = useSearchEngine();

    if (isError) {
        throw error;
    }

    if (engine.isError) {
        throw engine.error;
    }

    const title = translate("product-search-title", locale);

    if (isLoading || engine.isLoading) {
        const subtitle = translate("product-search-loading", locale);
        return <ProductList title={title} subtitle={subtitle} loading={true} products={null} categories={null} />;
    }

    const query = searchParams.get("q");
    if (!query) {
        const subtitle = translate("product-search-no-query", locale);
        return <ProductList title={title} subtitle={subtitle} loading={false} products={null} categories={null} />;
    }

    const searchResults = engine.search(query);
    if (searchResults === null) {
        throw new Error(translate("product-search-engine-error", locale));
    }
    
    if (searchResults.length === 0) {
        const subtitle = translate("product-search-no-results", locale);
        return <ProductList title={title} subtitle={subtitle} loading={false} products={null} categories={null} />;
    }

    const subtitle = null;
    const products = searchResults.map(code => {
        return { code, ...index[code] };
    });

    return <ProductList title={title} subtitle={subtitle} loading={false} products={products} categories={categories} />;
}
