import { React } from 'react';
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// Importing straight from 'bootstrap' causes the dropdown listeners to disappear.
// https://stackoverflow.com/a/68158568
import Tooltip from 'bootstrap/js/dist/tooltip';
import Collapse from 'bootstrap/js/dist/collapse';

import { useProductData } from "../DataFetcher";
import { logViewProduct } from "../FirebaseFunctions";
import { Translate, translate, useLocale, useCurrency, formatAsCurrency } from '../Locale';

import { ReferralButton, ReferralLink } from '../components/ReferralButton';
import { PriceChart } from '../components/PriceChart';
import { ProductBreadcrumbs, BreadcrumbLink, getBreadcrumbObjects } from '../components/Breadcrumbs';
import { ProductImage } from '../components/ProductImage';
import { ProductPriceWatch } from '../components/ProductPriceWatch';
import { PriceBadges, IsDiscounted } from '../components/PriceBadges';

export default function Product(params) {    
    const locale = useLocale();
    const { productId } = useParams();
    const [isLoading, isError, error, index, categories, options, product, info] = useProductData(productId);
    const variant = useSelectedVariant(product);
    const priceHistory = usePriceHistory(product);

    useEffect(() => {
        if (isLoading)
            return;

        //displayCheapestVarint();
        logViewProduct(product.code);
    }, [isLoading]);

    if (isError)
        throw error;

    if (isLoading)
        return translate("loading-ellipsis", locale);

    const indexProduct = index[product.code];
    const variants = product.variants;

    const priceHistoryDependentVisibility = (priceHistory && priceHistory.length) ? "" : "d-none";
    const variantsDependentVisibility = (variants && variants.length) >= 2 ? "" : "d-none";

    return (
        <div id="product">
            <div className="row mt-3">
                <div className="col">
                    <Header indexProduct={indexProduct} product={product} categories={categories} info={info}/>
                </div>
            </div>

            <div className={`row mt-3 ${priceHistoryDependentVisibility}`}>
                <div className="col">
                    <ProductPriceWatch variant={variant} priceHistory={priceHistory} />
                </div>
            </div>

            <div className={`row mt-3 ${priceHistoryDependentVisibility}`}>
                <div className="col">
                    <PriceHistory priceHistory={priceHistory} product={product} />
                </div>
            </div>

            <div className={`row mt-3 ${variantsDependentVisibility}`}>
                <div className="col">
                    <Variants product={product} options={options} />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col">
                    <ProductDetails product={product} categories={categories} info={info}/>
                </div>
            </div>
        </div>
    );
}

function Header(params) {
    const indexProduct = params.indexProduct;
    const product = params.product;
    const categories = params.categories;
    const info = params.info;

    // https://getbootstrap.com/docs/5.3/utilities/display/#hiding-elements
    return (
        <>
            <div className="row">
                <div className="col d-md-none">
                    <h2><Translate string="product-price-history-title" /></h2>
                    <h3><Title product={product} /></h3>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-2 text-center">
                    <ProductImage product={product} thumbnail={false} />
                </div>
                <div className="col-sm-12 col-md-7">
                    <div className="row d-none d-md-block">
                        <div className="col">
                            <h2><Translate string="product-price-history-title" /></h2>
                            <h3><Title product={product} /></h3>
                        </div>
                    </div>
                    <div className="row d-none d-md-block mt-1">
                        <div className="col">
                            <ProductBreadcrumbs categories={categories} product={product} showLink={true} />
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            <span>
                                <Translate string="product-price-watch-instructions" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3 text-md-end">
                    <div className="row">
                        <div className="col-sm-8 col-md-12">
                            <PriceWidget product={product} info={info}/>
                        </div>
                    </div>
                    <div className={`row ${IsDiscounted(indexProduct) ? '' : 'mt-1 d-none'}`}>
                        <div className="col-md-12">
                            <PriceBadges product={indexProduct} />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <ReferralButton product={product} fullWidth={true} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function PriceHistory(params) {
    const priceHistory = params.priceHistory;
    const product = params.product;
    
    if (!priceHistory || !priceHistory.length) {
        return null;
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="d-flex flex-row">
                    <div className="pe-2">
                        <i className="bi bi-graph-down-arrow"></i>
                    </div>
                    <div className="align-self-center">
                        <a href="#history">
                            <Translate string="product-price-history-title" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <PriceChart data={priceHistory} />

                {/* Without this dummy div the chart won't resize automatically
                    when the viewport changes. */}
                {/*
                <div className="invisible">{params.product.code}</div>
                */}

                <div className="mt-3">
                    <ReferralButton product={product} fullWidth={true} />
                </div>
            </div>
        </div>
    );
}

function ProductDetails(params) {
    const product = params.product;
    const categories = params.categories;
    const info = params.info;

    const locale = useLocale();
    const variant = useSelectedVariant(product);

    // Product has an array of categories, pick the first one if available
    var categoryId;
    if (product.categories && product.categories.length > 0) {
        categoryId = product.categories[0];
    } else {
        categoryId = null;
    }

    var categoryLink = null;
    var manufacturerLink = null;

    var breadcrumbs = getBreadcrumbObjects(categories, categoryId, product.manufacturer, locale);
    if (breadcrumbs.length >= 2) {
        var l1 = breadcrumbs.slice(-1)[0];
        var l2 = breadcrumbs.slice(-2)[0];
        if (l1.isCategory) {
            categoryLink = <BreadcrumbLink breadcrumb={l1} showLink={true} />;
            manufacturerLink = null;
        } else {
            categoryLink = <BreadcrumbLink breadcrumb={l2} showLink={true} />;
            manufacturerLink = <BreadcrumbLink breadcrumb={l1} showLink={true} />;
        }
    }

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
    const date = new Date(Date.parse(info.date));
    const dateOptions = {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h11',
        //dateStyle: 'short',
        //timeStyle: 'short',
    };

    
    const model = variant ? variant.model : null;

    const formattedDate = date.toLocaleString(locale, dateOptions);

    const details = {
        [translate("product-details-code", locale)]: product.code,
        [translate("product-details-category", locale)]: categoryLink,
        [translate("product-details-manufacturer", locale)]: manufacturerLink,
        [translate("product-details-model", locale)]: model,
        [translate("product-details-price", locale)]: <FormattedPrice product={params.product} />,
        [translate("product-details-last-update", locale)]: formattedDate,
    };
    
    const validDetails = [];
    Object.entries(details).map(([description, value]) => {
        if (!value) {
            return;
        }
        validDetails.push({
            description,
            value
        });
    });

    const items = validDetails.map((entry, index) => {
        return (
            <div key={index} className="row row-striped">
                <div className="col-6 col-md-4 col-lg-2">
                    <span>
                        { entry.description }
                    </span>
                </div>
                <div className="col-6 col-md-8 col-lg-10">
                    <span>
                        { entry.value }
                    </span>
                </div>
            </div>
        );
    });

    return (
        <div className="card">
            <div className="card-header">
                <div className="d-flex flex-row">
                    <div className="pe-2">
                        <i className="bi bi-info-square"></i>
                    </div>
                    <div className="align-self-center">
                        <a href="#details">
                            <Translate string="product-details-title" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="card-body ms-3 me-3">
                { items }
            </div>
        </div>
    );
}

function Title(props) {
    const product = props.product;
    return <ReferralLink product={product} className="product-title" title={null} text={product.title}/>
}

function FormattedPrice(params) {
    const locale = useLocale();
    const currency = useCurrency();
    const history = usePriceHistory(params.product);

    if (!history || history.length == 0) {
        return <Translate string="in-store-only"/>;
    }

    const entry = history[history.length - 1];
    if (!entry.price) {
        return <Translate string="in-store-only"/>;
    }

    return formatAsCurrency(entry.price, locale, currency);
}

function PriceWidget(params) {
    const locale = useLocale();
    const info = params.info;

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('#priceTooltip');
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
    });

    const date = new Date(Date.parse(info.date));

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
    const dateOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',

        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h11',

        //dateStyle: 'medium',
        //timeStyle: 'short',
    };

    const formattedDate = date.toLocaleString(locale, dateOptions);
    const storeId = info.storeId;
    const priceTooltip = translate("product-price-tooltip", locale);

    return (
        <>
            <div className="row">
                <div className="col">
                    <span className="product-price">
                        <FormattedPrice product={params.product} />
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <span className="product-price-note">
                        <Translate string="product-price-as-of"/>&nbsp;
                        {formattedDate},&nbsp;
                        <span style={{"whiteSpace": "nowrap"}}>
                            <Translate string="product-price-store"/>{storeId}&nbsp;
                            <i className="bi bi-info-circle" id="priceTooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title={priceTooltip}/>
                        </span>
                    </span>
                </div>
            </div>
        </>
    );
}

function Variants(params) {
    const product = params.product;
    const options = params.options;
    const variants = product.variants;

    const [searchParams, setSearchParams] = useSearchParams();
    const code = useSelectedVariant(product).code;
    const locale = useLocale();

    const infos = [];
    var title = null;

    for (let i = 0; i < variants.length; i++) {
        const variant = variants[i];

        if (variant.optionIds.length === 0) {
            infos.push({
                code: variant.code,
                text: variant.code
            });
            continue;
        }
        
        const optionId = variant.optionIds[0];
        const option = options.options[optionId];

        infos.push({
            code: variant.code,
            text: option ? option.text : variant.code
        });

        if (title === null && option) {
            const descriptor = options.descriptors[option.descriptorId];
            if (descriptor) {
                title = descriptor.text;
            }
        }        
    }

    if (title === null) {
        title = translate("product-variants-title", locale);
    }

    const buttons = infos.map((variant) => {
        var className;
        if (variant.code == code) {
            className = "btn btn-sm btn-outline-dark";
        } else {
            className = "btn btn-sm btn-light";
        }

        return (
            <a href="" key={variant.code} className={className} onClick={(event) => {
                event.preventDefault();
                searchParams.set("variant", variant.code);
                setSearchParams(searchParams);
            }}>
                {variant.text}
            </a>
        );
    });

    return (
        <div className="card accordion" id="variantsContainer">
            <div className="accordion-item">
                <div className="card-header accordion-header" id="variantsHeader">
                    <button className="accordion-button collapsed" id="variantsAccordionButton" type="button" data-bs-toggle="collapse" data-bs-target="#variantsBody" aria-expanded="true" aria-controls="variantsBody">
                        <div className="d-flex flex-row">
                            <div className="pe-2">
                                <i className="bi bi-collection"></i>
                            </div>
                            <div className="align-self-center">
                                <a href="#history">
                                    { title }
                                </a>
                            </div>
                        </div>
                    </button>
                </div>
                <div id="variantsBody" className="accordion-collapse collapse" aria-labelledby="variantsHeader" data-bs-parent="#variantsContainer">
                    <div className="card-body accordion-body">
                        { buttons }
                    </div>
                </div>
            </div>
        </div>
    );
}

function useSelectedVariant(product) {
    const [searchParams] = useSearchParams();

    if (!product || !product.variants || product.variants.length === 0)
        return null;

    const variants = product.variants;

    const code = searchParams.get("variant");
    if (code == null) {
        return variants[0];
    }

    const variant = variants.find(v => v.code == code);
    if (variant) {
        return variant;
    } else {
        console.warn(`Variant ${code} not found`);
        return variants[0];
    }
}

function usePriceHistory(product) {
    const variant = useSelectedVariant(product);
    if (!variant)
        return null;
    
    return variant.priceHistory;
}


/*
function displayCheapestVarint(product) {
    const [searchParams, setSearchParams] = useSearchParams();
    const code = getCheapestVariantCode(product);
    if (code) {
        searchParams.set("variant", code);
        setSearchParams(searchParams);
    }
}

function getCheapestVariantCode(product) {
    const variants = product.variants;
    if (!variants || variants.length < 2) {
        return null;
    }

    var code;
    var price = Number.MAX_SAFE_INTEGER;
    for (let i = 0; i < variants.length; i++) {
        const variant = variants[i];

        if (!variant.priceHistory || !variant.priceHistory.length)
            continue;

        const entry = variant.priceHistory[variant.priceHistory.length - 1];
        if (entry.price < price) {
            code = variant.code;
            price = entry.price;
        }
    }

    return code;
}

*/
