import { React } from 'react';
import { Translate } from '../Locale'
import { ContactInfo } from '../components/Contact'

export default function About(props) {
    return (
        <div id="about">
            <div className="row mt-3">
                <div className="col">
                    <h2>
                        <Translate string="about-title"/>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="border-top"/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p>
                        <Translate string="about-description-1" />
                    </p>
                    <p>
                        <Translate string="about-description-2" />
                    </p>
                </div>
            </div>
            
            <ContactInfo />
        </div>
    );

}
