import { React } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import { addDays, date_eq, date_le, snapToThursday } from '../DateUtils';

import { translate, useLocale, useCurrency, formatAsCurrency } from "../Locale";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export function PriceChart(params) {
    const locale = useLocale();
    const currency = useCurrency();

    const dt = normalizeData(params.data, false);

    const dateOptions = {
        //year: 'numeric',
        month: 'short',
        day: 'numeric'
    };

    // https://www.chartjs.org/docs/latest/samples/scriptable/line.html
    const options = {
        responsive: true,
        plugins: {
            legend: false,
            title: {
                text: "",
                display: false
            },


            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        
                        if (context.parsed.y !== null) {
                            label += formatAsCurrency(context.parsed.y, locale, currency);
                        }

                        return label;
                    }
                }
            }

        },
        elements: {
            line: {
                //fill: true,
                //backgroundColor: getLineColor,
                borderColor: (ctx) => {
                    return '#212529';
                },
            },
            point: {
                pointStyle: 'circle',
                hoverRadius: 0,
                radius: (ctx) => {
                    const i = ctx.index;
                    const data = ctx.dataset.data;
                    if (i === 0 || data[i] !== data[i - 1]) {
                        return 15;
                    } else {
                        return 0;
                    }
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value, index, values) {
                        return formatAsCurrency(value, locale, currency);
                    }
                }
            }
        },
    };

    const data = {
        labels: dt.map(row => {
            const date = new Date(Date.parse(row.date));
            return date.toLocaleString(locale, dateOptions);
        }),
        datasets: [{
            label: translate("price", locale),
            data: dt.map(row => row.price),

            //borderColor: 'rgb(75, 192, 192)',
            //backgroundColor: 'rgba(75, 192, 192, 0.5)',
            stepped: 'left',
        }]
    };

    return <Line options={options} data={data} />;
}

// In theory sales start every Thursday, so if the history
// only has one data point between Thursday and Wednesday
// we can assign it to Thursday. This is important mostly
// for older data collections where dumps were generated
// erractically (on Saturdays or Sundays, for instance).
function normalizeData(history, shouldSnapToThursday) {
    if (!history || history.length == 0) {
        return [];
    }

    function getHistoryDate(i) {
        const date = new Date(Date.parse(history[i].date));
        if (shouldSnapToThursday) {
            return snapToThursday(date);
        } else {
            return date;
        }
    }

    const newHistory = [];

    const first = getHistoryDate(0);
    const today = new Date();

    for (let i = 0, current = first; date_le(current, today, false);) {
        
        newHistory.push({
            date: current,
            price: history[i].price
        });

        // 1) Set current to the next date.
        current = addDays(current, 1);

        // 2) Increment 'i' if the dataset contains 'current'.
        if (i + 1 < history.length) {
            const next = getHistoryDate(i + 1);
            if (date_eq(current, next, false)) {
                i++;
            }
        }
    }

    return newHistory;
}
