import { React } from 'react';

export function ToolbarDropdown(props) {
    const iconClassName = props.iconClassName;
    const value = props.value;
    const setValue = props.setValue;
    const values = props.values;

    const items = values.map(entry => {
        var className;
        if (entry.value === value) {
            className = "dropdown-item active";
        } else {
            className = "dropdown-item";
        }

        function handleClick(event) {
            event.preventDefault();
            setValue(entry.value);
        }

        return (
            <li key={entry.value}>
                <a href="" className={className} onClick={handleClick}>
                    { entry.text }
                </a>
            </li>
        );
    });

    return (
        <div className="btn-group" role="group">
            <button type="button" className="btn btn-md btn-light product-list-toolbar-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i className={`bi ${iconClassName}`}></i>
            </button>
            <ul className="dropdown-menu">
                { items }
            </ul>
        </div>
    );
}
