import { React } from 'react';
import { useEffect, useState } from "react";
import { Translate, hasTranslation, translate, useLocale, useCurrency, formatAsCurrency } from '../Locale';
import { Alert } from './Alert';
import { subscribe, logException } from "../FirebaseFunctions";

// Importing straight from 'bootstrap' causes the dropdown listeners to disappear.
// https://stackoverflow.com/a/68158568
import Collapse from 'bootstrap/js/dist/collapse';

export function ProductPriceWatch(params) {
    const variant = params.variant;
    const priceHistory = params.priceHistory;
    
    const locale = useLocale();
    const [state, setState] = useState({ email: "", price: "" });
    const [alert, setAlert] = useState({ show: false, type: "", body: "" });
    const [showSuggestions, setShowSuggestions] = useState(false);

    const emailPlaceholder = ""; // "email@example.com";
    const valuePlaceholder = ""; // "1000.00";
    const minPrice = "0";
    const maxPrice = ""; // Present price - 0.01?
    const step = "0.01";

    function handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setState({ ...state, [name]: value });
    }

    function handleSuggestedPriceSelected(value) {
        setState({ ...state, price: value });
    }

    function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        hideAlert();

        const form = document.getElementById("priceWatchForm");
        const isValid = form.checkValidity();
        if (isValid) {
            submitForm();
        } else {
            form.classList.add('was-validated');
        }
    }

    function submitForm() {
        const priceNum = parseFloat(state.price);

        disableControls(true);
        subscribe(state.email, variant.code, priceNum, locale).then(() => {
            const success = true;
            const message = translate("product-price-watch-success", locale);
            showAlert(success, message);
            resetForm();
        }).catch(error => {
            var details;
            if (hasTranslation(error.message)) {
                details = translate(error.message, locale);
            } else {
                details = error.message;
            }

            const success = false;
            const message = (
                <>
                    <Translate string="product-price-watch-failure" />
                    <hr />
                    <b><Translate string="product-price-watch-failure-details-label" /></b>
                    <span>&nbsp;</span>
                    {details}
                </>
            );
            
            showAlert(success, message);
            logException(`Error subscribing to price watch: ${error.message}`, true);
        }).finally(() => {
            disableControls(false);
        });
    }

    function resetForm() {
        const form = document.getElementById("priceWatchForm");
        form.classList.remove('was-validated');
        setState({ email: "", price: "" });
    }

    function disableControls(disabled) {
        const controls = document.querySelectorAll('.control');
        Array.from(controls).forEach(control => {
            control.disabled = disabled;
        });
    }

    function showAlert(success, message) {
        setAlert({
            show: true,
            type: success ? "success" : "danger",
            body: message
        });
    }

    function hideAlert() {
        setAlert({ ...alert, show: false });
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="d-flex flex-row">
                    <div className="pe-2">
                        <i className="bi bi-envelope"></i>
                    </div>
                    <div className="align-self-center">
                        <a href="#watch">
                            <Translate string="product-price-watch-title" />
                        </a>
                    </div>
                </div>
            </div>

            <form id="priceWatchForm" className="row needs-validation p-3" noValidate onSubmit={handleSubmit}>
                <div className="col-12">
                    <Alert show={alert.show} type={alert.type} body={alert.body} onClose={hideAlert} />
                </div>
                <div className="col-sm-12 col-lg-6">
                    <label htmlFor="emailInput" className="form-label">
                        <Translate string="product-price-watch-email-label" />
                    </label>
                    <div className="input-group has-validation">
                        <span className="input-group-text" id="emailInputPrepend"><i className="bi-envelope" /></span>
                        <input
                            className="control form-control"
                            placeholder={emailPlaceholder}
                            aria-label={translate("product-price-watch-email-aria-label", locale)}
                            aria-describedby="emailInputPrepend"
                            id="emailInput"
                            name="email"
                            type="email"
                            required
                            value={state.email}
                            onChange={handleInputChange} />
                        <div className="invalid-feedback">
                            <Translate string="product-price-watch-email-invalid" />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-6 mt-3 mt-lg-0">
                    <label htmlFor="priceInput" className="form-label">
                        <Translate string="product-price-watch-price-label" />
                    </label>
                    <div className="input-group has-validation">
                        <span className="input-group-text" id="priceInputPrepend"><i className="bi-currency-dollar" /></span>
                        <input
                            className="control form-control"
                            placeholder={valuePlaceholder}
                            aria-label={translate("product-price-watch-price-aria-label", locale)}
                            aria-describedby="priceInputPrepend"
                            id="priceInput"
                            name="price"
                            type="number"
                            required
                            min={minPrice}
                            max={maxPrice}
                            step={step}
                            value={state.price}
                            onChange={handleInputChange}
                            onFocus={() => setShowSuggestions(true)}
                            onBlur={() => setShowSuggestions(false)} />
                        <button className="control btn btn-light btn-outline-primary d-none d-lg-block" type="submit">
                            <Translate string="product-price-watch-track-sm" />
                        </button>
                        <div className="invalid-feedback">
                            <Translate string="product-price-watch-price-invalid" />
                        </div>
                    </div>
                    <ProductPriceSuggestions show={showSuggestions} priceHistory={priceHistory} onSelected={handleSuggestedPriceSelected} />
                </div>
                <div className="col-12 d-lg-none mt-3">
                    <button className="control btn btn-light btn-outline-primary w-100" type="submit">
                        <Translate string="product-price-watch-track-lg" />
                    </button>
                </div>
            </form>
        </div>
    );
}

function ProductPriceSuggestions(props) {
    const show = props.show;
    const priceHistory = props.priceHistory;
    const handleSelected = props.onSelected;

    useEffect(() => {
        var el = document.getElementById('priceSuggestionsAccordion');
        if (el) {
            const collapse = new Collapse(el, { toggle: false });
            show ? collapse.show() : collapse.hide();
        }
    }, [show]);

    if (!priceHistory || !priceHistory.length) {
        return null;
    }

    const jsx = (
        <div className="accordion accordion-flush mb-1" id="accordionExample">
            <div className="accordion-item">
                <div id="priceSuggestionsAccordion" className="accordion-collapse collapse" aria-labelledby="priceDropLabel" data-bs-parent="#accordionExample">
                    <div className="accordion-body" style={{ "padding": "1rem 0rem 0rem 0rem" }}>
                        <div className="row">
                            <div className="col-auto">
                                <ul className="list-unstyled">
                                    <ProductPriceSuggestion priceHistory={priceHistory} discount={3} onSelected={handleSelected}/>
                                    <ProductPriceSuggestion priceHistory={priceHistory} discount={5} onSelected={handleSelected}/>
                                    <ProductPriceSuggestion priceHistory={priceHistory} discount={10} onSelected={handleSelected}/>
                                    <ProductPriceSuggestion priceHistory={priceHistory} discount={15} onSelected={handleSelected}/>
                                </ul>
                            </div>
                            <div className="col-auto">
                                <ul className="list-unstyled">
                                    <ProductPriceSuggestion priceHistory={priceHistory} discount={"product-price-watch-any"} onSelected={handleSelected}/>
                                    <ProductPriceSuggestion priceHistory={priceHistory} discount={"product-price-watch-best"} onSelected={handleSelected}/>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return jsx;
}

function ProductPriceSuggestion(props) {
    const locale = useLocale();
    const currency = useCurrency();

    const priceHistory = props.priceHistory;
    const discount = props.discount;
    const handleSelected = props.onSelected;

    if (!priceHistory || !priceHistory.length) {
        return null;
    }

    var description;
    if (Number.isInteger(discount)) {
        const amount = calculateDiscountedPrice(discount);
        const fmtAmt = formatAsCurrency(amount, locale, currency);
        description = `-${discount.toString()}% (${fmtAmt})`;
    } else {
        description = translate(discount, locale);
    }

    return (
        <li>
            <a href="" onClick={(event) => { applyDiscount(event, discount); }}>
                { description }
            </a>
        </li>
    );

    function calculateDiscountedPrice(discount) {
        const currentPrice = getCurrentPrice(priceHistory);
        const discPrice = currentPrice - ((discount / 100) * currentPrice);
        return discPrice.toFixed(2);
    }

    function applyDiscount(event, disc) {
        event.preventDefault();

        const currentPrice = getCurrentPrice(priceHistory);
        if (currentPrice === 0) {
            handleSelected("");
        }

        var adjustedAmount = currentPrice;
        if (disc === "product-price-watch-any") {
            adjustedAmount -= 0.01;
            adjustedAmount = adjustedAmount.toFixed(2);
        } else if (disc === "product-price-watch-good") {
            adjustedAmount = getGoodPrice(priceHistory);
        } else if (disc === "product-price-watch-best") {
            adjustedAmount = getBestPrice(priceHistory);
        } else {
            adjustedAmount = calculateDiscountedPrice(disc);
        }

        handleSelected(adjustedAmount);
    }    
}

function getCurrentPrice(priceHistory) {
    const entry = priceHistory[priceHistory.length - 1];
    return entry.price ? entry.price : 0;
}

function getGoodPrice(priceHistory) {
    // TODO: implement
    return getCurrentPrice(priceHistory);
}

function getBestPrice(priceHistory) {
    var lowest = Number.MAX_SAFE_INTEGER;
    for (let i = 0; i < priceHistory.length; i++) {
        const entry = priceHistory[i];
        if (entry.price) {
            lowest = Math.min(lowest, entry.price);
        }
    }

    if (lowest === Number.MAX_SAFE_INTEGER) {
        return 0;
    } else {
        return lowest;
    }
}
