import { React } from 'react';
import { Link } from "react-router-dom";
import { Translate, useLocale, useCurrency, formatAsCurrency } from '../Locale';
import { ProductImage } from '../components/ProductImage';
import { ReferralButton } from '../components/ReferralButton';
import { BestPriceBadge, IsDiscounted } from '../components/PriceBadges';

export function ProductGridImpl(props) {
    const products = props.products;
    const categories = props.categories;

    var productGrid;
    if (products.length === 0) {
        productGrid = <ZeroResults/>
    } else {
        productGrid = <ProductGrid products={products} categories={categories}/>
    }

    return (
        <div id="productGrid">
            {productGrid}
        </div>
    );
}

function ZeroResults(props) {
    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="border-top"/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <p><Translate string="product-list-no-results"/></p>
                </div>
            </div>
        </>
    );
}

function ProductGrid(props) {
    const products = props.products;
    const categories = props.categories;

    const productsJsx = products.map((product, i) => {
        return <ProductCard key={product.code} product={product} />
    });

    return (
        <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6 g-3">
            {productsJsx}
        </div>
    );
}

function ProductCard(props) {
    const product = props.product;
    return (
        <div className="col">
            <div className="card h-100">
                <div className="card-img-top product-grid-card-img" >
                    <Badge product={product} />
                    <Image product={product} />
                </div>
                <div className="card-body">
                    <h5 className="card-title product-grid-card-title">
                        <Title product={product} />
                    </h5>
                </div>
                <div className="card-footer">
                    <PriceInfo product={product}/>
                </div>
                <div className="card-footer product-grid-button-container">
                    <ReferralButton product={product} fullWidth={true} text="product-grid-buy-button-text"/>
                </div>
            </div>
        </div>
    );
}

function Badge(props) {
    const product = props.product;

    if (!IsDiscounted(product)) {
        return null;
    }

    return (
        <div className="product-grid-badge-container">
            <BestPriceBadge product={product} />
        </div>
    );
}

function Image(props) {
    const product = props.product;
    return (
        <Link to={`/product/${product.code}`} title={product.title}>
            <ProductImage product={product} thumbnail={true} />
        </Link>
    );
}

function Title(props) {
    const product = props.product;
    return (
        <Link to={`/product/${product.code}`} title={product.title}>
            {product.title}
        </Link>
    );
}

function PriceInfo(props) {
    const locale = useLocale();
    const currency = useCurrency();
    const product = props.product;

    var currPriceJsx;
    if (product.priceRange) {
        const currPrice = formatAsCurrency(product.priceRange[0], locale, currency);
        currPriceJsx = (
            <div className="text-center product-grid-curr-price product-grid-green">
                {currPrice}
            </div>
        );
    } else {
        currPriceJsx = <Translate string="in-store-only"/>;
    }

    var prevPriceJsx;
    var saveJsx;
    if (product.priceRange && product.disc && product.disc.length >= 3) {
        const prevPrice = formatAsCurrency(product.priceRange[0] + product.disc[1], locale, currency);
        prevPriceJsx = (
            <div className="text-center product-grid-prev-price">
                <Translate string="product-grid-prev-price"/><span className="">{prevPrice}</span>
            </div>
        );

        const savePct = `${product.disc[2]}%`;
        const saveAmt = formatAsCurrency(product.disc[1], locale, currency);
        saveJsx = (
            <div className="text-center product-grid-save">
                <Translate string="product-grid-save"/>{savePct} (<span className="product-grid-green">{saveAmt}</span>)
            </div>
        );
    } else {
        prevPriceJsx = null;
        saveJsx = null;
    }

    return (
        <>
            {currPriceJsx}
            {prevPriceJsx}
            {saveJsx}        
        </>
    );
}
