// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";

const dev = process.env.NODE_ENV === "development";

const firebaseConfig = {
    apiKey: "AIzaSyB44y8nydi9w_35wambjXZI5gFmtWTmIf4",
    authDomain: "winged-record-376000.firebaseapp.com",
    databaseURL: "https://winged-record-376000-default-rtdb.firebaseio.com",
    projectId: "winged-record-376000",
    storageBucket: "winged-record-376000.appspot.com",
    messagingSenderId: "380895605517",
    appId: "1:380895605517:web:eff02d9935e3e88516b644",
    measurementId: "G-LQVGP8ZGSL"
};

const appCheckConfig = {
    provider: new ReCaptchaV3Provider('6LczzxwmAAAAALgUduly39NUT05nivO2I7qkVnHB'),
    isTokenAutoRefreshEnabled: true
};

// reCAPTCHA
// https://developers.google.com/recaptcha/intro
// https://firebase.google.com/docs/app-check/web/recaptcha-provider
// https://firebase.google.com/docs/app-check/web/debug-provider
// https://www.google.com/recaptcha/admin/
self.FIREBASE_APPCHECK_DEBUG_TOKEN = dev;

const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, appCheckConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);

if (dev) {
    connectFunctionsEmulator(functions, "localhost", 5001);
}

const hitCallable = httpsCallable(functions, 'hitCallable');
const subscribeRemote = httpsCallable(functions, 'subscribe');
const unsubscribeRemote = httpsCallable(functions, 'unsubscribe');

export async function subscribe(email, variant, price, locale ) {
    var language = locale.substring(0, 2); // ISO 639-1
    if (language === "en") {
        language = null; // Assume 'en' in case it's null.
    }

    try {
        await subscribeRemote({ email, variant, price, language });
        logSubscription(variant);
    } catch (error) {
        logException(`subscribe: '${error.code}'; '${error.message}'; '${error.details}'`, true);
        throw new Error(error.message);
    }
}

export async function unsubscribe(base64String) {
    if (typeof base64String !== "string") {
        logException(`Error unsubscribing: base64String is invalid (1): ${base64String}`, true);
        throw new Error("firebase-function-proxy-unsubscribe-invalid-args");
    }

    const buffer = atob(base64String); // Buffer.from(base64String, "base64");
    const params = buffer.toString().split(";");
    if (params.length != 2) {
        logException(`Error unsubscribing: base64String is invalid (2): ${base64String}`, true);
        throw new Error("firebase-function-proxy-unsubscribe-invalid-args");
    }

    const [id, email] = params;
    try {
        await unsubscribeRemote({ id, email });
    } catch (error) {
        logException(`unsubscribe: '${error.code}'; '${error.message}'; '${error.details}'`, true);
        throw new Error(error.message);
    }
}

// ****************************************************************************
// Functions
// https://support.google.com/analytics/answer/9267735
// https://developers.google.com/tag-platform/gtagjs/reference/events
// ****************************************************************************

export function hit(code) {
    const key = `visited_${code}`;
    const entry = localStorage.getItem(key);


    logEvent(analytics, 'notification_received');

    var expired;
    if (entry === null) {
        expired = true;
    } else {
        const timestamp = parseInt(entry);
        const ttl = 1000 * 20;
        expired = timestamp + ttl < Date.now();
    }

    if (expired) {
        console.debug("Expired!");

        // https://firebase.google.com/docs/functions/callable?gen=1st#web-version-9_2
        hitCallable({ productId: code }).then((result) => {
            // const data = result.data;
            localStorage.setItem(key, Date.now().toString());
        }).catch((error) => {
            //const code = error.code;
            //const message = error.message;
            //const details = error.details;
            console.error(error);
        });
    } else {
        console.debug("Not expired!");
    }
}

// ****************************************************************************
// Analytics
// https://support.google.com/analytics/answer/9267735
// https://developers.google.com/tag-platform/gtagjs/reference/events
// ****************************************************************************
export function logException(description, fatal) {
    logEventProxy('exception', {
        description,
        fatal
    });
}

// https://developers.google.com/tag-platform/gtagjs/reference/events#view_item
export function logViewProduct(code) {
    logEventProxy('view_item', {
        content_type: 'product',
        content_id: code
    });
}

// https://developers.google.com/tag-platform/gtagjs/reference/events#generate_lead
export function logOpenReferral(productCode, variantCode, price, source) {
    var code;
    
    if (!productCode) {
        code = "N/A";
    } else {
        code = productCode;
    }

    if (variantCode) {
        code = `${code}:${variantCode}`;
    }

    logEventProxy('generate_lead', {
        currency: 'CAD',
        content_id: code,
        value: price,
        source
    });
}

// https://developers.google.com/tag-platform/gtagjs/reference/events#page_view
export function logViewPage() {
    // useViewPage?
}

// https://developers.google.com/tag-platform/gtagjs/reference/events#search
export function logSearch() {
}

// https://developers.google.com/tag-platform/gtagjs/reference/events#view_item
// This event signifies that some content was shown to the user. Use this event
// to discover the most popular items viewed.
export function logProductsShown() {
}

function logEventProxy(event, parameters) {
    if (dev) {
        console.debug("logEventProxy:", event, parameters);
    } else {
        logEvent(analytics, event, parameters);
    }
}

function logSubscription(variant) {
    logEventProxy('join_group', {
        group_id: variant
    });
}
