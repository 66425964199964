import { React, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { hasTranslation, translate, useLocale } from '../Locale';

import { unsubscribe, logException } from "../FirebaseFunctions";

export default function Unsubscribe() {
  const { base64String } = useParams();
  const [state, setState] = useState({ status: "loading", details: null });
  const locale = useLocale();

  useEffect(() => {
    unsubscribe(base64String).then(() => {
      setState({ status: "success" });
    }).catch(error => {
      var details;
      if (hasTranslation(error.message)) {
        details = translate(error.message, locale);
      } else {
        details = error.message;
      }

      setState({ status: "failure", details });
      logException(`Error unsubscribing from price watch: ${error.message}`, true);
    });
  }, []);

  var message;
  if (state.status === "loading") {
    message = translate("unsubscribe-working", locale);
  } else if (state.status === "success") {
    message = translate("unsubscribe-success", locale);
  } else if (state.status === "failure") {
    message = translate("unsubscribe-failure", locale);
    message = `${message}: ${state.details}`;
  }

  return (
    <div className="container text-center">
      <div className="row">
        <div className="col mt-5">
          { message }
        </div>
      </div>
    </div>
  );
}
